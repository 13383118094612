import Config from '../Config';
import axios from 'axios';

import * as STATIC from './Json/static';

const ApiConfig = {
	...Config.axiosConfig
	, useJsonData: false
	, failProbability: 0 //number 0 to 1; 0 is "NEVER fail"; 1 is "ALWAYS fail", .5 is "50% chance to fail"
};

//Note to self: break this entire file apart in the future.

//General functions


export const user_status = async (profactToken) => {
	if ( ApiConfig.useJsonData ) {
		if( ApiConfig.failProbability > 0 ) {
			if( Math.random() < ApiConfig.failProbability )  {
				return STATIC.generic500;
			}
		}
		return ApiConfig.genericPlaceholder(200, 'NYI Status', []);
	}

	return axios
		.create( Config.axiosConfig )
		.get( '/api/login/status/', { }, {
			headers: { Authorization: `Bearer ${profactToken}` }
		} );

};


export const user_login = async (email, password) => {
	
	return axios
		.create( Config.axiosConfig )
		.post( '/api/login/', { 
			email: email,
			password: password
		} );
}

export const user_forgot_password = async (email) => {
	return axios
		.create( Config.axiosConfig )
		.put( '/api/login/forgot/', { 
			email: email
		} );
}
export const password_token_check = async (token) => {
	return axios
		.create( Config.axiosConfig )
		.get( `/api/login/validateResetToken/${token}`, { 
			
		} );
}
export const password_token_use = async (token, password) => {
	return axios
		.create( Config.axiosConfig )
		.put( `/api/login/reset/${token}`, { 
			newPass: password
		} );
}

export const update_my_profile = async (profactToken, getId, profileInfo ) => {
	if( getId.admin === true ) {
		return axios
			.create( Config.axiosConfig )
			.put( 
				`/api/admin/${getId.id}`,
				profileInfo,
				{
					headers: { Authorization: `Bearer ${profactToken}` }
				}
			);
	}else{
		return axios
			.create( Config.axiosConfig )
			.put( 
				`/api/cfa/${getId.id}`,
				profileInfo,
				{
					headers: { Authorization: `Bearer ${profactToken}` }
				}
			);
	}
}

export const get_contact_info = async (profactToken, contactId ) => {
	return axios
		.create( Config.axiosConfig )
		.get( 
			`/api/contact/${contactId}`,
			{
				headers: { Authorization: `Bearer ${profactToken}` }
			}
		);
}
export const update_contact_info = async (profactToken, contactId, contactInfo ) => {
	return axios
		.create( Config.axiosConfig )
		.put( 
			`/api/contact/${contactId}`,
			contactInfo,
			{
				headers: { Authorization: `Bearer ${profactToken}` }
			}
		);
}

export const reset_my_password = async (profactToken, pwInfo ) => {
	if( pwInfo.newPass !== pwInfo.newPassRetype ) {
		//todo: Do nothing right now, should check before calling this, but i want to add the retype later.
	}

	return axios
		.create( Config.axiosConfig )
		.put( 
			`/api/cfa/reset/`,
			{
				id: pwInfo.id,
				oldPass: pwInfo.oldPass,
				newPass: pwInfo.newPass
			},
			{
				headers: { Authorization: `Bearer ${profactToken}` }
			}
		);
}
export const reset_my_password_admin = async (profactToken, pwInfo ) => {
	if( pwInfo.newPass !== pwInfo.newPassRetype ) {
		//todo: Do nothing right now, should check before calling this, but i want to add the retype later.
	}

	return axios
		.create( Config.axiosConfig )
		.put( 
			`/api/admin/reset/`,
			{
				id: pwInfo.id,
				oldPass: pwInfo.oldPass,
				newPass: pwInfo.newPass
			},
			{
				headers: { Authorization: `Bearer ${profactToken}` }
			}
		);
}

//Home Page

//Get the news
export const AUTO_update_news = async (profactToken, newsInfo) => {
	//if news info's id exist, we're updating,
	if( newsInfo.id ) {
		return update_new_item(profactToken, newsInfo);
	}else{
		//else this is a new news item.
		return add_new_item(profactToken, newsInfo);
	}
}
export const add_new_item = async (profactToken, newsInfo) => {

	return axios
		.create( Config.axiosConfig )
		.post( `/api/news`, {
			title: newsInfo.title,
			content: newsInfo.content,
			sticky: newsInfo.sticky,
			expires: newsInfo.expires,
		}
		,{
			headers: { Authorization: `Bearer ${profactToken}` }
	 	} );
}
export const update_new_item = async (profactToken, newsInfo) => {

	return axios
		.create( Config.axiosConfig )
		.put( `/api/news/${newsInfo.id}`, {
			title: newsInfo.title,
			content: newsInfo.content,
			sticky: newsInfo.sticky,
			expires: newsInfo.expires,
		}
		,{
			headers: { Authorization: `Bearer ${profactToken}` }
	 	} );
}
export const get_public_news = async () => {
	
	return axios
		.create( Config.axiosConfig )
		.get( '/api/news/public', { } )

};
export const get_news = async () => {
	
	return axios
		.create( Config.axiosConfig )
		.get( '/api/news', { } )

};
export const get_specific_news = async (profactToken, newsId) => {
	
	return axios
		.create( Config.axiosConfig )
		.get( `/api/news/${newsId}` ,{
			headers: { Authorization: `Bearer ${profactToken}` }
	 	} );

};
export const delete_news = async (profactToken, newsId) => {
	
	return axios
		.create( Config.axiosConfig )
		.delete( `/api/news/${newsId}` ,{
			headers: { Authorization: `Bearer ${profactToken}` }
	 	} );

};

//CFA
/**
 * This function registers a CFA
 */
// Registration Page
export const post_cfa = async (formData)  => {
	//console.log(Config.axiosConfig );
	return axios.create( Config.axiosConfig )
		.post( 
			'/api/cfa',
			formData
		);
}

//CFA > Training Modules
export const get_cfa_public_profile = async (cId) => {
	if ( ApiConfig.useJsonData ) {
		if( ApiConfig.failProbability > 0 ) {
			if( Math.random() < ApiConfig.failProbability )  {
				return STATIC.generic500;
			}
		}

		return STATIC.generic200;
	}
	return axios
		.create( Config.axiosConfig )
		.get( `/api/cfa/public/${cId}`, {} );
		//.get( '/api/content/course/1/' )
};
export const get_cfa_single_module = async (profactToken, courseId) => {
	if ( ApiConfig.useJsonData ) {
		if( ApiConfig.failProbability > 0 ) {
			if( Math.random() < ApiConfig.failProbability )  {
				return STATIC.generic500;
			}
		}

		return STATIC.generic200;
	}
	return axios
		.create( Config.axiosConfig )
		.get( `/api/content/course/${courseId}`, {
			headers: { Authorization: `Bearer ${profactToken}` }
	 	});
		//.get( '/api/content/course/1/' )
};
export const get_cfa_training_modules = async (profactToken) => {
	if ( ApiConfig.useJsonData ) {
		if( ApiConfig.failProbability > 0 ) {
			if( Math.random() < ApiConfig.failProbability )  {
				return STATIC.generic500;
			}
		}

		return STATIC.generic200;
	}
	return axios
		.create( Config.axiosConfig )
		.get( '/api/content/', {
			headers: { Authorization: `Bearer ${profactToken}` }
	 	});
		//.get( '/api/content/course/1/' )
};
export const get_cfa_specific_training_modules = async (profactToken, courseId) => {
	if ( ApiConfig.useJsonData ) {
		if( ApiConfig.failProbability > 0 ) {
			if( Math.random() < ApiConfig.failProbability )  {
				return STATIC.generic500;
			}
		}

		return STATIC.generic200;
	}
	return axios
		.create( Config.axiosConfig )
		.get( `/api/content/${courseId}`, {
			headers: { Authorization: `Bearer ${profactToken}` }
	 	});
		//.get( '/api/content/course/1/' )
};
export const get_cfa_specific_training_content = async (profactToken, userId,courseId, contentId) => {
	
	return axios
		.create( Config.axiosConfig )
		.post( `/api/training`, {
			userId: userId.id,
			courseId: courseId,
			contentId: contentId,
		}
		,{
			headers: { Authorization: `Bearer ${profactToken}` }
	 	} );
};

export const get_online_training_material = async (profactToken) => {
	return axios
		.create( Config.axiosConfig )
		//.get( `/api/course/public`, {
		.get( `/api/training/material/`, {
			headers: { Authorization: `Bearer ${profactToken}` }
		 } );
};
export const get_online_training_content = async (profactToken, courseId) => {
	courseId = courseId || '';
	return axios
		.create( Config.axiosConfig )
		//.get( `/api/course/public`, {
		.get( `/api/training/material/${courseId}`, {
			headers: { Authorization: `Bearer ${profactToken}` }
		 } );
};
//might change.
export const get_course_content = async (profactToken) => {
	return axios
		.create( Config.axiosConfig )
		.get( `/api/course/public`, {
			headers: { Authorization: `Bearer ${profactToken}` }
	 	} );
};
export const get_credits_by_cfa = async (profactToken, cfaId) => {
	return axios
		.create( Config.axiosConfig )
		.get( `/api/credit/cfa/${cfaId}`, {
			headers: { Authorization: `Bearer ${profactToken}` }
	 	} );
};
export const get_ce_courses_and_cfa_credits = async (profactToken, cfaId) => {
	return axios
		.all([
			get_course_content(profactToken),
			get_credits_by_cfa(profactToken, cfaId),
			get_online_training_material(profactToken)
		])
};
export const register_for_ce_course = async (profactToken, cfaId, courseId) => {
	return axios
		.create( Config.axiosConfig )
		.post( `/api/credit/`,{
			userId: cfaId,
			courseId:courseId
		}, {
			headers: { Authorization: `Bearer ${profactToken}` }
	 	} );
};

export const get_cfa_training_completion = async (profactToken, cfaId, courseId) => {
	if ( ApiConfig.useJsonData ) {
		if( ApiConfig.failProbability > 0 ) {
			if( Math.random() < ApiConfig.failProbability )  {
				return STATIC.generic500;
			}
		}
		return {
			data: STATIC.cfaListPublic
		};
	}
	return axios
		.create( Config.axiosConfig )
		.get( `/api/training/cfa/${cfaId}/course/${courseId}`, {
			body: {
				courseId: courseId
			},
			headers: { Authorization: `Bearer ${profactToken}` }
		} )


};


export const AUTO_admin_bulk_credits = async ( profactToken, newCfas, updateCfas, deleteCfas ) => {
	//Credit info should have creditsEarned and courseId
	const allItems = [];


	allItems.push( admin_bulk_add(profactToken, newCfas ));
	allItems.push( admin_bulk_update(profactToken, updateCfas ));
	deleteCfas.forEach(deleteCredit => {
		allItems.push( admin_delete_credit(profactToken, deleteCredit.creditId) );
	});

	return 	axios.all(allItems);
	
}

export const admin_bulk_add = async (profactToken, cfaItems) => {
	/*{
			userId: cfaId,
			courseId:courseId
		}
	*/
	return axios
		.create( Config.axiosConfig )
		.post( `/api/credit/bulkAdd`, cfaItems, 
		{
			headers: { Authorization: `Bearer ${profactToken}` }
		} )
}
export const admin_bulk_update = async (profactToken, cfaItems) => {
	/*
      userId: credit.userId,
      courseId: credit.courseId,
      creditEarned: credit.creditEarned,
	*/
	return axios
		.create( Config.axiosConfig )
		.put( `/api/credit/bulkUpdate`, cfaItems, 
		{
			headers: { Authorization: `Bearer ${profactToken}` }
		} )
}
export const admin_delete_credit = async (profactToken, creditId) => {
	/*
      userId: credit.userId,
      courseId: credit.courseId,
      creditEarned: credit.creditEarned,
	*/
	return axios
		.create( Config.axiosConfig )
		.delete( `/api/credit/${creditId}`, 
		{
			headers: { Authorization: `Bearer ${profactToken}` }
		} )
}





export const AUTO_admin_course = async ( profactToken, courseInfo ) => {
	// {"paid": true, "payConf": "jsofaua309a8308520u984759487tjfifo", "payAmount": 40}
	//console.log('[add_new_admin]', profactToken, adminInfo);

	//if news info's id exist, we're updating,
	if( courseInfo.courseId ) {
		return admin_update_course(profactToken, courseInfo);
	}else{
		//else this is a new news item.
		return admin_create_course(profactToken, courseInfo);
	}
}
export const admin_create_course = async (profactToken, courseInfo) => {
	return axios
		.create( Config.axiosConfig )
		.post( `/api/course`, {
			title: courseInfo.title,
			passingScore: courseInfo.passingScore || 70,
			cost: courseInfo.cost || 0.00,
			credits: courseInfo.credits,
			sections: courseInfo.sections || 0,
			numQuestions:  courseInfo.numQuestions ||0,
			examTimeLimit: courseInfo.examTimeLimit || 0,
			beginDate: courseInfo.beginDate,
			description: courseInfo.description,
		}, {
			headers: { Authorization: `Bearer ${profactToken}` }
		} )
}


export const admin_update_course = async (profactToken, courseInfo) => {
	return axios
		.create( Config.axiosConfig )
		.put( `/api/course/${courseInfo.courseId}`, {
			title: courseInfo.title,
			passingScore: courseInfo.passingScore || 70,
			cost: courseInfo.cost || 0.00,
			credits: courseInfo.credits,
			sections: courseInfo.sections || 0,
			numQuestions:  courseInfo.numQuestions ||0,
			examTimeLimit: courseInfo.examTimeLimit || 0,
			beginDate: courseInfo.beginDate,
			description: courseInfo.description,
		}, {
			headers: { Authorization: `Bearer ${profactToken}` }
		} )
}
export const admin_get_all_courses_with_credits = async (profactToken) => {
	
	return 	axios.all([
		admin_get_all_courses(profactToken),
		admin_get_all_credits(profactToken) 
	]);
	/*
	return axios
		.create( Config.axiosConfig )
		.get( `/api/course/`, {
			headers: { Authorization: `Bearer ${profactToken}` }
		} )
		*/
};
export const admin_get_course_info = async (profactToken, courseId) => {
	if( courseId === 'cfa' ) {

		return axios
		.create( Config.axiosConfig )
		.get( `/api/course/?title="CFA EXAM"`, {
			headers: { Authorization: `Bearer ${profactToken}` }
		} )
	}
	return axios
		.create( Config.axiosConfig )
		.get( `/api/course/${courseId}`, {
			headers: { Authorization: `Bearer ${profactToken}` }
		} )
};
export const admin_get_all_courses = async (profactToken) => {
	return axios
		.create( Config.axiosConfig )
		.get( `/api/course/`, {
			headers: { Authorization: `Bearer ${profactToken}` }
		} )
};
export const admin_get_all_credits = async (profactToken) => {
	return axios
		.create( Config.axiosConfig )
		.get( `/api/credit/`, {
			headers: { Authorization: `Bearer ${profactToken}` }
		} )
};
export const GET_API_COURSE = async (profactToken, courseId) => {
	return axios
		.create( Config.axiosConfig )
		.get( `/api/course/${courseId}/`, {
			headers: { Authorization: `Bearer ${profactToken}` }
		} )
};


//CFA > Certified Applicators List

export const get_cfa_public = async () => {
	if ( ApiConfig.useJsonData ) {
		if( ApiConfig.failProbability > 0 ) {
			if( Math.random() < ApiConfig.failProbability )  {
				return STATIC.generic500;
			}
		}
		return {
			data: STATIC.cfaListPublic
		};
	}
	return axios
		.create( Config.axiosConfig )
		.get( '/api/cfa/public', { } )

};

export const get_cfa_by_id = async (profactToken, cfaId) => {
	return axios
		.create( Config.axiosConfig )
		.get( `/api/cfa/${cfaId}`, {
			headers: { Authorization: `Bearer ${profactToken}` }
		} )

};
//CFA Examination time
//starts a new exam
export const start_exam = async (profactToken, cfaId, courseId, creditId, spanish) => {
	//console.log('START EXAM', courseId, creditId);
	return axios
		.create( Config.axiosConfig )
		.post( `/api/exam/start`, {
			userId: cfaId,
			courseId: courseId,
			creditId: creditId,
			spanish: spanish
		}, {
			headers: { Authorization: `Bearer ${profactToken}` }
		} )
}
export const post_exam = async (profactToken, cfaId) => {

	return axios
		.create( Config.axiosConfig )
		.post( `/api/exam`, {
			userId: cfaId
		}, {
			headers: { Authorization: `Bearer ${profactToken}` }
		} )
}
export const post_exam_response = async (profactToken, cfaId, questionId, selection, examId) => {
/*
          userId: req.body.userId,
          questionId: req.body.questionId,
          selection: req.body.selection,
		  examId: req.body.examId
*/		  
	return axios
		.create( Config.axiosConfig )
		.post( `/api/examResponse/${examId}`, {
			userId: cfaId,
			questionId: questionId,
			selection: selection,
			examId: examId,
		}, {
			headers: { Authorization: `Bearer ${profactToken}` }
		} )
}
export const put_exam_response = async (profactToken, cfaId, examResponseId, questionId, selection, examId) => {
/*
          userId: req.body.userId,
          questionId: req.body.questionId,
          selection: req.body.selection,
		  examId: req.body.examId
*/		  
	return axios
		.create( Config.axiosConfig )
		.put( `/api/examResponse/exam/${examId}/response/${examResponseId}`, {
			userId: cfaId,
			questionId: questionId,
			selection: selection,
			examId: examId,
		}, {
			headers: { Authorization: `Bearer ${profactToken}` }
		} )
}

export const grade_exam = async ( profactToken,  examId, creditId, userId ) => {

	return axios
		.create( Config.axiosConfig )
		.put( `/api/exam/grade/${examId}`, {
			creditId: creditId,
			userId: userId
		}, {
			headers: { Authorization: `Bearer ${profactToken}` }
		} )
}

export const get_exam_overall = async ( profactToken ) => {

	return axios
		.create( Config.axiosConfig )
		.get( `/api/exam/overall`, {
			headers: { Authorization: `Bearer ${profactToken}` }
		} )
}

export const get_exam_progress = async ( profactToken, courseId, examId) => {
	return axios
		.create( Config.axiosConfig )
		.get( `/api/exam/progress/${examId}`, {
			headers: { Authorization: `Bearer ${profactToken}` }
		} )
}

export const get_exam_active_now = async ( profactToken ) => {

	return axios
		.create( Config.axiosConfig )
		.get( `/api/exam/activeNow`, {
			headers: { Authorization: `Bearer ${profactToken}` }
		} )
}

export const get_tfas_by_cfas = async ( profactToken, cfaId ) => {

	return axios
		.create( Config.axiosConfig )
		.get( `/api/tfa/cfa/${cfaId}`, {
			headers: { Authorization: `Bearer ${profactToken}` }
		} )
}

export const AUTO_tfa_to_cfa = async ( profactToken, tfaInfo ) => {
	if( tfaInfo.tfaId ) {
		const contactInfo = {
			street: tfaInfo.homeStreet || ' ',
			city: tfaInfo.homeCity,
			state: tfaInfo.homeState,
			zip: tfaInfo.homeZip || '00000',
			phone: tfaInfo.homePhone || '0000000000',
			county: tfaInfo.homeCounty
		};

		if( tfaInfo.homeAddressId ) {
			return axios.all([
				update_tfa_to_cfa(profactToken, tfaInfo),
				update_contact_info(profactToken, tfaInfo.homeAddressId, contactInfo ) 
			]);
		}else{
			const myNewContact = await CREATE_CONTACT_ID(profactToken, contactInfo);
			if( myNewContact.data.code === 'CONTACT_CREATED' ) {
				tfaInfo.homeAddressId = myNewContact.data.data.id;
				return axios.all([
					update_tfa_to_cfa(profactToken, tfaInfo),
					//update_contact_info(profactToken, tfaInfo.homeAddressId, contactInfo ) 
				]);
			}else{
				return axios.all([
					update_tfa_to_cfa(profactToken, tfaInfo),
					//update_contact_info(profactToken, tfaInfo.homeAddressId, contactInfo ) 
				]);
			}
		}

	}

	return 	add_tfa_to_cfa(profactToken, tfaInfo);
};

const CREATE_CONTACT_ID = async ( profactToken, contactInfo ) => {

	return axios
		.create( Config.axiosConfig )
		.post( `/api/contact/`,
		contactInfo, {
			headers: { Authorization: `Bearer ${profactToken}` }
		} )
}

export const add_tfa_to_cfa = async ( profactToken, tfaInfo ) => {

	return axios
		.create( Config.axiosConfig )
		.post( `/api/tfa/`,
		tfaInfo, {
			headers: { Authorization: `Bearer ${profactToken}` }
		} )
}
export const update_tfa_to_cfa = async ( profactToken, tfaInfo ) => {
	
	return axios
		.create( Config.axiosConfig )
		.put( `/api/tfa/${tfaInfo.tfaId}`,
		tfaInfo, {
			headers: { Authorization: `Bearer ${profactToken}` }
		} )
}

export const delete_tfa_to_cfa = async ( profactToken, tfaId ) => {
	return axios
		.create( Config.axiosConfig )
		.delete( `/api/tfa/${tfaId}`,
		{
			headers: { Authorization: `Bearer ${profactToken}` }
		} )
}

//TFA

//TFA > Trained Applicators List
export const get_tfa_public = async () => {
	if ( ApiConfig.useJsonData ) {
		if( ApiConfig.failProbability > 0 ) {
			if( Math.random() < ApiConfig.failProbability )  {
				return STATIC.generic500;
			}
		}
		return {
			data: STATIC.tfaPublicList
		};
	}
	return axios
		.create( Config.axiosConfig )
		.get( '/api/tfa/public', { } )

};
export const get_tfa_info = async (profactToken, tfaId) => {
	
	return axios
		.create( Config.axiosConfig )
		.get( `/api/tfa/${tfaId}`, {
			headers: { Authorization: `Bearer ${profactToken}` }
		} )

};


//Admin Items
export const admin_dashboard_stats = async (profactToken) => {
	return axios
		.create( Config.axiosConfig )
		.get( 
			`/api/admin/statistics`,
			{
				headers: { Authorization: `Bearer ${profactToken}` }
			}
		);
}
export const admin_quick_peek = async (profactToken, lastName, firstName) => {
	return axios
		.create( Config.axiosConfig )
		.get( 
			`/api/cfa/quickLook?lastName=${encodeURIComponent(lastName)}&firstName=${encodeURIComponent(firstName)}`,
			{
				headers: { Authorization: `Bearer ${profactToken}` }
			}
		);
}

//Admin Manage Users
export const merge_accounts = async ( profactToken, cfaId, oldId )  => {
	return axios
		.create( Config.axiosConfig )
		.put( 
			`/api/cfa/manualMerge`,
			{
				oldId,
				cfaId
			},
			{
				headers: { Authorization: `Bearer ${profactToken}` }
			}
		);
}
export const delete_admin = async ( profactToken, adminId ) => {
	return axios
		.create( Config.axiosConfig )
		.delete( `/api/admin/${adminId}`,
		{
			headers: { Authorization: `Bearer ${profactToken}` }
		} )
}
export const delete_cfa = async ( profactToken, cfaId ) => {
	return axios
		.create( Config.axiosConfig )
		.delete( `/api/cfa/${cfaId}`,
		{
			headers: { Authorization: `Bearer ${profactToken}` }
		} )
}
///cfa stuff
export const admin_delete_pay_form = async (profactToken, payDetails ) => {

	return axios
		.create( Config.axiosConfig )
		.delete( `/api/payment/${payDetails.id}` ,{
			headers: { Authorization: `Bearer ${profactToken}` }
	 	} );

};

export const admin_apply_single_course_credit = async (profactToken, userId, creditId,  creditEarned, payId, courseId ) => {
	return axios
		.create( Config.axiosConfig )
		.put( `/api/credit/${creditId}`,{
			userId: userId,
			creditEarned: creditEarned,
			payId: payId,
			courseId: courseId,
		}
		/*
		{
			userId: cfaId,
			courseId:courseId
		}, */
		, {
			headers: { Authorization: `Bearer ${profactToken}` }
	 	} );
};
export const AUTO_admin_payment = async (profactToken, payDetails ) => {
/*
Payment API Points

Method is a little sloppy, but it needs to switch between the following:

o Unpaid New: POST /api/payment
o Unpaid Update: PUT /api/payment
o Is already paid for, but not in the database yet: POST /api/payment/{SPECIALIZED_POINT:['tfa','exam','course','renewal']}
o Has just been cleared by Barbera and is now considered PAID: PUT /api/payment/{SPECIALIZED_POINT:['tfa','exam','course','renewal']}
*/

//if payDetails.payMethod === 'adminWaived' ||  payDetails.paid === true, do the special points.
//Otherwise handle it in the generic payment api

	if( payDetails.payMethod === 'adminWaived' ||  (payDetails.paid === true ||  payDetails.paid === 1) ) {

		if( payDetails.payPurpose === 'exam' ) {
			//Use PUT to process exam payment
			if( payDetails.id ) {
				return payment_cfa_exam_finalize(profactToken, payDetails.userId, true, payDetails.payConf, payDetails.payAmount, JSON.stringify(payDetails.cart),
					payDetails.poNumber,
					payDetails.invoiceNumber,
					payDetails.payMethod,
					payDetails.id
				);
			//It's a new payment that's been paid, use the special point
			}else{
				return payment_cfa_exam(profactToken, payDetails.userId, true, payDetails.payConf, payDetails.payAmount, JSON.stringify(payDetails.cart),
					payDetails.poNumber,
					payDetails.invoiceNumber,
					payDetails.payMethod
				);
			}

		}else if( payDetails.payPurpose === 'tfa' ) {
			//console.log('Okay, going to TFA this user: ', payDetails);
			
			if( payDetails.id ) {
				return payment_cfa_tfas_finalize( profactToken, payDetails.userId,
					payDetails.myTfas, payDetails.paid, payDetails.payConf, payDetails.payAmount, JSON.stringify(payDetails.cart),
					payDetails.poNumber,
					payDetails.invoiceNumber,
					payDetails.payMethod,
					payDetails.id
				);
				
			}else{
				return payment_cfa_tfas( profactToken, payDetails.userId,
					payDetails.myTfas, payDetails.paid, payDetails.payConf, payDetails.payAmount, JSON.stringify(payDetails.cart),
					payDetails.poNumber,
					payDetails.invoiceNumber,
					payDetails.payMethod
				);
			}
		}else if( payDetails.payPurpose === 'renewal' ) {
			//console.log('Okay, going to update renewals this user: ', payDetails)

			if( payDetails.id ) {
				return payment_cfa_renewal_finalize( profactToken, payDetails.userId,
					payDetails.myRenewalYears, payDetails.paid, payDetails.payConf, payDetails.payAmount, JSON.stringify(payDetails.cart),
					payDetails.poNumber,
					payDetails.invoiceNumber,
					payDetails.payMethod,
					payDetails.id
				);
			}else{
				return payment_cfa_renewal( profactToken, payDetails.userId,
					payDetails.myRenewalYears, payDetails.paid, payDetails.payConf, payDetails.payAmount, JSON.stringify(payDetails.cart),
					payDetails.poNumber,
					payDetails.invoiceNumber,
					payDetails.payMethod
				);
			}
		}else if( payDetails.payPurpose === 'course' ) {
			//console.log('Okay, going to update the course this user: ', payDetails)

			if( payDetails.id ) {
				
				return payment_cfa_course_finalize ( profactToken, payDetails.userId, payDetails.myCourseId,
					payDetails.myCreditId,
					payDetails.paid, payDetails.payConf, payDetails.payAmount, JSON.stringify(payDetails.cart),
					payDetails.poNumber,
					payDetails.invoiceNumber,
					payDetails.payMethod,
					payDetails.id
				);
			}else{
				return payment_cfa_course ( profactToken, payDetails.userId, payDetails.myCourseId,
					payDetails.myCreditId,
					payDetails.paid, 
					payDetails.payConf, payDetails.payAmount, JSON.stringify(payDetails.cart),
					payDetails.poNumber,
					payDetails.invoiceNumber,
					payDetails.payMethod
				);
			}
		}
	}else{
		//Use generic method
		if( payDetails.id ) {
			return axios
				.create( Config.axiosConfig )
				.put( 
					`/api/payment/${payDetails.id}`,
					{
						...payDetails,
						cart: JSON.stringify(payDetails.cart)
					},
					{
						headers: { Authorization: `Bearer ${profactToken}` }
					}
				);
		}else{
			return axios
				.create( Config.axiosConfig )
				.post( 
					`/api/payment/`,
					{
						...payDetails,
						cart: JSON.stringify(payDetails.cart)
					},
					{
						headers: { Authorization: `Bearer ${profactToken}` }
					}
				);
		}
	}
	/*
	<option value="exam">Exam</option>
	<option value="tfa">TFAs</option>
	<option value="renewal">Renewal</option>
	<option value="course">Course</option>
	*/
};

export const update_cfa_profile = async (profactToken, profileInfo ) => {
	return axios
		.create( Config.axiosConfig )
		.put( 
			`/api/cfa/${profileInfo.id}`,
			profileInfo,
			{
				headers: { Authorization: `Bearer ${profactToken}` }
			}
		);
}
export const COMBO_business_contact = async (profactToken, businessAddressId, businessAddress, businessName ) => {
	
	return axios
		.all([
			update_contact_info( profactToken, businessAddressId, businessAddress ),
			update_cfa_profile( profactToken, businessName )
		])
		/*
	return axios
		.create( Config.axiosConfig )
		.put( 
			`/api/cfa/${profileInfo.id}`,
			profileInfo,
			{
				headers: { Authorization: `Bearer ${profactToken}` }
			}
		);
		*/
}



//Admin stuff
export const AUTO_update_admin_list = async ( profactToken, adminInfo ) => {
	// {"paid": true, "payConf": "jsofaua309a8308520u984759487tjfifo", "payAmount": 40}
	//console.log('[add_new_admin]', profactToken, adminInfo);

	//if news info's id exist, we're updating,
	if( adminInfo.adminId ) {
		return update_admin(profactToken, adminInfo);
	}else{
		//else this is a new news item.
		return add_new_admin(profactToken, adminInfo);
	}
}
export const add_new_admin = async ( profactToken, adminInfo ) => {
	// {"paid": true, "payConf": "jsofaua309a8308520u984759487tjfifo", "payAmount": 40}
	//console.log('[add_new_admin]', profactToken, adminInfo);

	return axios
		.create( Config.axiosConfig )
		.post( `/api/admin`, {
			firstName: adminInfo.firstName,
			lastName: adminInfo.lastName,
			email: adminInfo.email,
			password: adminInfo.password,
		} ,{
			headers: { Authorization: `Bearer ${profactToken}` }
	 	} );
}
export const update_admin = async ( profactToken, adminInfo ) => {
	// {"paid": true, "payConf": "jsofaua309a8308520u984759487tjfifo", "payAmount": 40}
	//console.log('[add_new_admin]', profactToken, adminInfo);
	if( adminInfo.password ) {
		const pwInfo = {
			id: adminInfo.adminId,
			oldPass: adminInfo.oldPass,
			newPass: adminInfo.newPass
		}
		return axios
			.all([
				axios
					.create( Config.axiosConfig )
					.put( `/api/admin/${adminInfo.adminId}`, {
						firstName: adminInfo.firstName,
						lastName: adminInfo.lastName,
						email: adminInfo.email,
						//password: adminInfo.password,
					} ,{
						headers: { Authorization: `Bearer ${profactToken}` }
				} ),
				reset_my_password_admin(profactToken, pwInfo),
			])

	}

	return axios
		.create( Config.axiosConfig )
		.put( `/api/admin/${adminInfo.adminId}`, {
			firstName: adminInfo.firstName,
			lastName: adminInfo.lastName,
			email: adminInfo.email,
			//password: adminInfo.password,
		} ,{
			headers: { Authorization: `Bearer ${profactToken}` }
	 	} );
}
export const get_users_in_system = async (profactToken) => {
	return axios
		.all([
			get_cfa_list(profactToken),
			get_admin_list(profactToken),
		])
};
export const get_cfa_list = async ( profactToken ) => {
	return axios
		.create( Config.axiosConfig )
		.get( `/api/cfa`, {
			headers: { Authorization: `Bearer ${profactToken}` }
		} )
}
export const get_admin_list = async ( profactToken ) => {
	return axios
		.create( Config.axiosConfig )
		.get( `/api/admin`, {
			headers: { Authorization: `Bearer ${profactToken}` }
		} )
}


export const get_admin_by_id = async (profactToken, adminId) => {
	//console.log('[get_admin_by_id]', profactToken, adminId);
	if ( ApiConfig.useJsonData ) {
		if( ApiConfig.failProbability > 0 ) {
			if( Math.random() < ApiConfig.failProbability )  {
				return STATIC.generic500;
			}
		}
		return {
			data: STATIC.cfaListPublic
		};
	}
	return axios
		.create( Config.axiosConfig )
		.get( `/api/admin/${adminId}`, {
			headers: { Authorization: `Bearer ${profactToken}` }
		} )

};


//All Payment items
//Admin function only
export const payment_all_history = async ( profactToken ) => {
	//console.log('[payment_all_history]', profactToken);

	return axios
		.create( Config.axiosConfig )
		.get( `/api/payment/`, {
			headers: { Authorization: `Bearer ${profactToken}` }
		} );
}

export const generic_update_payment = async (
	profactToken, key, payload 
	, cfaId, type, paid, payConf, payAmount, cart, 
	extraVariable1, extraVariable2,
	poNumber='', invoiceNumber='', payMethod = 'online'
) => {

	
	if( type === 'exam' ) {
		return axios
		.create( Config.axiosConfig )
		.put( 
			`/api/payment/${key}`,
			{
				userId: cfaId,
				paid: paid,
				payPurpose: type,
				payConf: payConf,
				payAmount: payAmount,
				cart: cart,
				poNumber: poNumber,
				invoiceNumber: invoiceNumber,
				payMethod: payMethod,
			},
			{
				headers: { Authorization: `Bearer ${profactToken}` }
			}
		);
	}else if( type === 'tfa' ) {
		return axios
		.create( Config.axiosConfig )
		.put( 
			`/api/payment/${key}`,
			{
				userId: cfaId,
				paid: paid,
				tfas: extraVariable1,
				payPurpose: type,
				payConf: payConf,
				payAmount: payAmount,
				cart: cart,
				poNumber: poNumber,
				invoiceNumber: invoiceNumber,
				payMethod: payMethod,
			},
			{
				headers: { Authorization: `Bearer ${profactToken}` }
			}
		);
		/*
		return payment_cfa_tfas( 
			//profactToken, cfaId, tfas, paid, payConf, payAmount, cart, poNumber='', invoiceNumber='', payMethod = 'online'
			profactToken, cfaId, extraVariable1, paid, payConf, payAmount, cart, '', '', 'online' 
		);
		*/
	}else if( type === 'course' ) {
		return axios
		.create( Config.axiosConfig )
		.put( 
			`/api/payment/${key}`,
			{
				userId: cfaId,
				paid: paid,
				courseId: extraVariable1,
				creditId: extraVariable2,
				payPurpose: type,
				payConf: payConf,
				payAmount: payAmount,
				cart: cart,
				poNumber: poNumber,
				invoiceNumber: invoiceNumber,
				payMethod: payMethod,
			},
			{
				headers: { Authorization: `Bearer ${profactToken}` }
			}
		);
		/*
		return payment_cfa_course( 
			//profactToken, cfaId, courseId, creditId, paid, payConf, payAmount, cart, poNumber='', invoiceNumber='', payMethod = 'online'
			profactToken, cfaId, extraVariable1, extraVariable2, paid, payConf, payAmount, cart, '', '', 'online' 
		);
		*/
	}else if( type === 'renewal' ) {
		return axios
		.create( Config.axiosConfig )
		.put( 
			`/api/payment/${key}`,
			{
				userId: cfaId,
				paid: paid,
				renewalYear: extraVariable1,
				payPurpose: type,
				payConf: payConf,
				payAmount: payAmount,
				cart: cart,
				poNumber: poNumber,
				invoiceNumber: invoiceNumber,
				payMethod: payMethod,
			},
			{
				headers: { Authorization: `Bearer ${profactToken}` }
			}
		);
		/*
		return payment_cfa_renewal( 
			//profactToken, cfaId, renewalYear, paid, payConf, payAmount, cart, poNumber='', invoiceNumber='', payMethod = 'online'
			profactToken, cfaId, extraVariable1, paid, payConf, payAmount, cart, '', '', 'online' 
		);
		*/
	}


/*
	return axios
		.create( Config.axiosConfig )
		.put( 
			`/api/payment/${key}`,
			{
				userId: cfaId,
				paid: paid,
				payPurpose: type,
				payConf: payConf,
				payAmount: payAmount,
				cart: cart,
				poNumber: poNumber,
				invoiceNumber: invoiceNumber,
				payMethod: payMethod,
			},
			{
				headers: { Authorization: `Bearer ${profactToken}` }
			}
		);
		*/
/*
	if( type === 'exam' ) {
		return payment_cfa_exam( 
			//profactToken, cfaId, paid, payConf, payAmount, cart, poNumber='', invoiceNumber='', payMethod = 'online' 
			profactToken, cfaId, paid, payConf, payAmount, cart, '', '', 'online' 
		);
	}else if( type === 'tfa' ) {
		return payment_cfa_tfas( 
			//profactToken, cfaId, tfas, paid, payConf, payAmount, cart, poNumber='', invoiceNumber='', payMethod = 'online'
			profactToken, cfaId, extraVariable1, paid, payConf, payAmount, cart, '', '', 'online' 
		);
	}else if( type === 'course' ) {
		return payment_cfa_course( 
			//profactToken, cfaId, courseId, creditId, paid, payConf, payAmount, cart, poNumber='', invoiceNumber='', payMethod = 'online'
			profactToken, cfaId, extraVariable1, extraVariable2, paid, payConf, payAmount, cart, '', '', 'online' 
		);
	}else if( type === 'renewal' ) {
		return payment_cfa_renewal( 
			//profactToken, cfaId, renewalYear, paid, payConf, payAmount, cart, poNumber='', invoiceNumber='', payMethod = 'online'
			profactToken, cfaId, extraVariable1, paid, payConf, payAmount, cart, '', '', 'online' 
		);
	}
	return null;
		*/
}
/**
 * 
 * @param {*} type can be `exam`, `tfa`, `course`, `renewal`
 * @param {boolean} paid 
 * @param {*} payConf Confirmation nunmber again. Just leaving it for now.
 * @param {*} amount The total amount
 * @param {*} itemsJsonString cart JSON Stringified
 * @param {*} extraVariable1 if type is `tfa`: this will be the TFAs array. if `renewal` this will be the renewal years. if `course` this will be the courseid
 * @param {*} extraVariable2 if type is `course` this will be the credit id
 */
export const insert_pending_online_payment = async ( 
	profactToken, key, payload 
	, cfaId, type, paid, payConf, payAmount, cart, 
	extraVariable1, extraVariable2
) => {
	
	if( type === 'exam' ) {
		return payment_cfa_exam( 
			//profactToken, cfaId, paid, payConf, payAmount, cart, poNumber='', invoiceNumber='', payMethod = 'online' 
			profactToken, cfaId, paid, payConf, payAmount, cart, '', '', 'online' 
		);
	}else if( type === 'tfa' ) {
		return payment_cfa_tfas( 
			//profactToken, cfaId, tfas, paid, payConf, payAmount, cart, poNumber='', invoiceNumber='', payMethod = 'online'
			profactToken, cfaId, extraVariable1, paid, payConf, payAmount, cart, '', '', 'online' 
		);
	}else if( type === 'course' ) {
		return payment_cfa_course( 
			//profactToken, cfaId, courseId, creditId, paid, payConf, payAmount, cart, poNumber='', invoiceNumber='', payMethod = 'online'
			profactToken, cfaId, extraVariable1, extraVariable2, paid, payConf, payAmount, cart, '', '', 'online' 
		);
	}else if( type === 'renewal' ) {
		return payment_cfa_renewal( 
			//profactToken, cfaId, renewalYear, paid, payConf, payAmount, cart, poNumber='', invoiceNumber='', payMethod = 'online'
			profactToken, cfaId, extraVariable1, paid, payConf, payAmount, cart, '', '', 'online' 
		);
	}
	return null;
}

/*
export const insert_pending_online_payment = async ( profactToken, key, payload ) => {
	//console.log('[insert_pending_online_payment]', profactToken, key, payload);

	return axios
		.create( Config.axiosConfig )
		.post( `/api/pendingOnlinePayment`, {
			key: key,
			payload: payload
		} ,{
			headers: { Authorization: `Bearer ${profactToken}` }
	 	} );
}
*/
export const retrieve_pending_online_payment_by_payid = async ( profactToken, payId ) => {
	////console.log('[retrieve_pending_online_payment_by_payid]', profactToken, payId);
	return axios
		.create( Config.axiosConfig )
		.get( `/api/payment/${payId}` ,{
			headers: { Authorization: `Bearer ${profactToken}` }
	 	} );
}
export const retrieve_pending_online_payment = async ( profactToken, key ) => {
	////console.log('[retrieve_pending_online_payment]', profactToken, key);

	return axios
		.create( Config.axiosConfig )
		.get( `/api/pendingOnlinePayment/${key}`, {
			headers: { Authorization: `Bearer ${profactToken}` }
		} );
}


export const payment_cfa_history = async ( profactToken, cfaId ) => {
	//console.log('[payment_cfa_history]', profactToken, cfaId);

	return axios
		.create( Config.axiosConfig )
		.get( `/api/payment/cfa/${cfaId}`, {
			headers: { Authorization: `Bearer ${profactToken}` }
		} );
}


export const payment_cfa_exam = async ( profactToken, cfaId, paid, payConf, payAmount, cart, poNumber='', invoiceNumber='', payMethod = 'online' ) => {
	// {"paid": true, "payConf": "jsofaua309a8308520u984759487tjfifo", "payAmount": 40}
	//console.log('[payment_cfa_exam]', profactToken, cfaId, cart);

	return axios
		.create( Config.axiosConfig )
		.post( `/api/payment/exam`, {
			userId: cfaId,
			paid: paid,
			payPurpose:'exam',
			payConf: payConf,
			payAmount: payAmount,
			cart: cart,
			poNumber: poNumber,
			invoiceNumber: invoiceNumber,
			payMethod: payMethod,
		} ,{
			headers: { Authorization: `Bearer ${profactToken}` }
	 	} );
}
export const payment_cfa_exam_finalize = async ( profactToken, cfaId, paid, payConf, payAmount, cart, poNumber='', invoiceNumber='', payMethod = 'online' 
	, payId=''
) => {
	// {"paid": true, "payConf": "jsofaua309a8308520u984759487tjfifo", "payAmount": 40}
	//console.log('[payment_cfa_exam]', profactToken, cfaId);

	return axios
		.create( Config.axiosConfig )
		.put( `/api/payment/exam/${payId}`, {
			userId: cfaId,
			paid: paid,
			payConf: payConf,
			payPurpose:'exam',
			payAmount: payAmount,
			cart: cart,
			poNumber: poNumber,
			invoiceNumber: invoiceNumber,
			payMethod: payMethod,
		} ,{
			headers: { Authorization: `Bearer ${profactToken}` }
	 	} );
}
export const payment_cfa_tfas_finalize = async ( profactToken, cfaId, tfas, paid, payConf, payAmount, cart, poNumber='', invoiceNumber='', payMethod = 'online', payId='') => {
	// {"paid": true, "payConf": "jsofaua309a8308520u984759487tjfifo", "payAmount": 40}
	//console.log('[payment_cfa_tfas_finalize]', profactToken, cfaId, tfas);

	return axios
		.create( Config.axiosConfig )
		.put( `/api/payment/tfa/${payId}`, {
			userId: cfaId,
			tfas: tfas,
			paid: paid,
			payPurpose: 'tfa',
			payConf: payConf,
			payAmount: payAmount,
			payMethod: payMethod,
			cart: cart,
			poNumber: poNumber,
			invoiceNumber: invoiceNumber,
		} ,{
			headers: { Authorization: `Bearer ${profactToken}` }
	 	} );
}

export const payment_cfa_tfas = async ( profactToken, cfaId, tfas, paid, payConf, payAmount, cart, poNumber='', invoiceNumber='', payMethod = 'online') => {
	// {"paid": true, "payConf": "jsofaua309a8308520u984759487tjfifo", "payAmount": 40}
	//console.log('[payment_cfa_tfas]', profactToken, cfaId, tfas);

	return axios
		.create( Config.axiosConfig )
		.post( `/api/payment/tfa`, {
			userId: cfaId,
			tfas: tfas,
			paid: paid,
			payConf: payConf,
			payPurpose: 'tfa',
			payAmount: payAmount,
			payMethod: payMethod,
			cart: cart,
			poNumber: poNumber,
			invoiceNumber: invoiceNumber,
		} ,{
			headers: { Authorization: `Bearer ${profactToken}` }
	 	} );
}

export const payment_cfa_course_finalize = async ( profactToken, cfaId, courseId, creditId, paid, payConf, payAmount, cart  
	, poNumber='', invoiceNumber='', payMethod = 'online'
	, payId=''
) => {
	// {"paid": true, "payConf": "jsofaua309a8308520u984759487tjfifo", "payAmount": 40}
	//console.log('[payment_cfa_course]', profactToken, cfaId, courseId);

	return axios
		.create( Config.axiosConfig )
		.put( `/api/payment/credit/${payId}`, {
			userId: cfaId,
			creditId: creditId,
			courseId: courseId,
			payPurpose: 'course',
			paid: paid,
			payConf: payConf,
			payAmount: payAmount,
			cart: cart,
				
			payMethod: payMethod,
			poNumber: poNumber,
			invoiceNumber: invoiceNumber,
		} ,{
			headers: { Authorization: `Bearer ${profactToken}` }
	 	} );
}
export const payment_cfa_course = async ( profactToken, cfaId, courseId, creditId, paid, payConf, payAmount, cart  
	, poNumber='', invoiceNumber='', payMethod = 'online'
) => {
	// {"paid": true, "payConf": "jsofaua309a8308520u984759487tjfifo", "payAmount": 40}
	//console.log('[payment_cfa_course]', profactToken, cfaId, courseId);

	return axios
		.create( Config.axiosConfig )
		.post( `/api/payment/credit/${creditId}`, {
			userId: cfaId,
			creditId: creditId,
			courseId: courseId,
			payPurpose: 'course',
			paid: paid,
			payConf: payConf,
			payAmount: payAmount,
			cart: cart,
				
			payMethod: payMethod,
			poNumber: poNumber,
			invoiceNumber: invoiceNumber,
		} ,{
			headers: { Authorization: `Bearer ${profactToken}` }
	 	} );
}
export const payment_cfa_renewal_finalize = async ( profactToken, cfaId, renewalYear, paid, payConf, payAmount, cart 
	, poNumber='', invoiceNumber='', payMethod = 'online'
	, payId=''
) => {
	// {"paid": true, "payConf": "jsofaua309a8308520u984759487tjfifo", "payAmount": 40}
	//console.log('[payment_cfa_renewal_finalize]', profactToken, cfaId, renewalYear);

	return axios
		.create( Config.axiosConfig )
		.put( `/api/payment/renewal/${payId}`, {
			userId: cfaId,
			renewalYear: renewalYear,
			paid: paid,
			payPurpose:'renewal',
			payConf: payConf,
			payAmount: payAmount,			
			payMethod: payMethod,
			cart: cart,
			poNumber: poNumber,
			invoiceNumber: invoiceNumber,
		} ,{
			headers: { Authorization: `Bearer ${profactToken}` }
	 	} );
}

export const payment_cfa_renewal = async ( profactToken, cfaId, renewalYear, paid, payConf, payAmount, cart  
	, poNumber='', invoiceNumber='', payMethod = 'online' 
	) => {
	// {"paid": true, "payConf": "jsofaua309a8308520u984759487tjfifo", "payAmount": 40}
	//console.log('[payment_cfa_renewal]', profactToken, cfaId, renewalYear);

	return axios
		.create( Config.axiosConfig )
		.post( `/api/payment/renewal`, {
			userId: cfaId,
			renewalYear: renewalYear,
			paid: paid,
			payPurpose:'renewal',
			payConf: payConf,
			payAmount: payAmount,		
			payMethod: payMethod,
			cart: cart,
			poNumber: poNumber,
			invoiceNumber: invoiceNumber,
		} ,{
			headers: { Authorization: `Bearer ${profactToken}` }
	 	} );
}


export const get_credit_info = async ( profactToken, creditId ) => {

	return axios
		.create( Config.axiosConfig )
		.get( `/api/credit/${creditId}`, {
			headers: { Authorization: `Bearer ${profactToken}` }
		} );
}
/*
export const get_potential_ce_exam = (profactToken, courseId, creditId) => {

	return axios
		.all([
			get_online_training_content(profactToken, courseId),
			get_credit_info(profactToken, creditId),
		]);

}*/

//Mass Mail Information
/**
 * 
 * @param {string} profactToken 
 * @param {FormData} formData 
 */
export const MASS_MAILER_JSON = async ( profactToken, jsonData ) => {

	return axios
		.create( Config.axiosConfig )
		.post( `/api/news/mail`, 
		jsonData,
		{
			headers: { Authorization: `Bearer ${profactToken}` }
	 	} );
}
export const MASS_MAILER = async ( profactToken, formData ) => {

	return axios
		.create( Config.axiosConfig )
		.post( `/api/news/mail`, 
		formData,
		{
			headers: { Authorization: `Bearer ${profactToken}` }
	 	} );
}
/*
.create( {
	...Config.axiosConfig,
	responseType: 'blob'
	} )
*/


//Download stuff

export const AUTO_DOWNLOAD_CSV = async ( profactToken, downloadOptions ) => {
	const myQueryParams = [];
	for( const i in downloadOptions.options) {
		if(downloadOptions.options[i] !== null && downloadOptions.options[i] !== undefined ){
			myQueryParams.push(`${encodeURIComponent(i)}=${encodeURIComponent(downloadOptions.options[i])}` )
		}
	}
	if( downloadOptions.type === 'cfa' ) {

		return axios
			.create( {
				...Config.axiosConfig,
				responseType: 'blob'
			 } )
			.get( `/api/cfa/download/?${myQueryParams.join('&')}`, {
				headers: { Authorization: `Bearer ${profactToken}` }
			} );
	}else if( downloadOptions.type === 'cfa-status-download' ) {

		return axios
			.create( {
				...Config.axiosConfig,
				responseType: 'blob'
			 } )
			.get( `/api/cfa/statusDownload/?${myQueryParams.join('&')}`, {
				headers: { Authorization: `Bearer ${profactToken}` }
			} );
	}else if( downloadOptions.type === 'cfa-recert-download' ) {

		return axios
			.create( {
				...Config.axiosConfig,
				responseType: 'blob'
			 } )
			.get( `/api/cfa/recertDownload/?${myQueryParams.join('&')}`, {
				headers: { Authorization: `Bearer ${profactToken}` }
			} );
	}else if( downloadOptions.type === 'tfa' ) {

		return axios
			.create( {
				...Config.axiosConfig,
				responseType: 'blob'
			 } )
			.get( `/api/tfa/download/?${myQueryParams.join('&')}`, {
				headers: { Authorization: `Bearer ${profactToken}` }
			} );
	}else if( downloadOptions.type === 'credits' ) {

		return axios
			.create( {
				...Config.axiosConfig,
				responseType: 'blob'
			 } )
			.get( `/api/credit/download/?${myQueryParams.join('&')}`, {
				headers: { Authorization: `Bearer ${profactToken}` }
			} );
	}else if( downloadOptions.type === 'payment' ) {

		return axios
			.create( {
				...Config.axiosConfig,
				responseType: 'blob'
			 } )
			.get( `/api/payment/download/?${myQueryParams.join('&')}`, {
				headers: { Authorization: `Bearer ${profactToken}` }
			} );
	}else if( downloadOptions.type === 'exam' ) {

		return axios
			.create( {
				...Config.axiosConfig,
				responseType: 'blob'
			 } )
			.get( `/api/exam/download/?${myQueryParams.join('&')}`, {
				headers: { Authorization: `Bearer ${profactToken}` }
			} );
	}else if( downloadOptions.type === 'question' ) {

		return axios
			.create( {
				...Config.axiosConfig,
				responseType: 'blob'
			 } )
			.get( `/api/question/download/?${myQueryParams.join('&')}`, {
				headers: { Authorization: `Bearer ${profactToken}` }
			} );
	}
}




//If you want to just write stuff (mainly for the developer)
export const MANUAL_WRITE = ( ) => {
	return axios
		.create( Config.axiosConfig )
};

export const DECODE_TOKEN = (decodeThisToken) => {

	//console.log('[DECODE_TOKEN] Start');
	
    let getAuthToken = null;
	let getId = null;
	let code = 'GENERAL_ERROR';

	if( decodeThisToken ) {
		try{ 
			getAuthToken = decodeThisToken;
			getId = JSON.parse( atob(decodeThisToken.split('.')[1]) );
			code = 'TOKEN_DECODED';
		}catch(loginStatusErr) {
			code = 'INVALID_DECODED_TOKEN';
		}
	}else{

		if( !localStorage.getItem('profact-token') ) {
			console.warn('No login token detected.');
			code="NO_TOKEN";
			return {
				getAuthToken: getAuthToken
			  , getId: getId
			  , code: code
		  };
	  }else{
		  //console.log('Found a token: ',localStorage.getItem('profact-token'));
		  try{ 
			  getAuthToken = localStorage.getItem('profact-token');
			  getId = JSON.parse( atob(localStorage.getItem('profact-token').split('.')[1]) );
			if( getId &&getId.exp < new Date().getTime()/1000 ) {
				
				localStorage.clear();
				console.warn('Token Expired detected.', getAuthToken, getId.exp, (new Date().getTime()/1000));
				code="EXPIRED_TOKEN";
				return {
					getAuthToken: null
					, getId: null
					, code: code
				};
			}
			console.log('Good token.');
			code = 'TOKEN_FOUND';
			REFRESH_TOKEN_EVERY_TIME({
				getAuthToken: getAuthToken
				, getId: getId
				, code: code
			})

		  }catch(loginStatusErr) {
			  code = 'INVALID_TOKEN';
		  }
	  }
	}

	
	return {
		getAuthToken: getAuthToken
	  , getId: getId
	  , code: code
  };
}
const REFRESH_TOKEN_EVERY_TIME = async (tokenInfo) => {
	//console.log('Refreshing Token', tokenInfo)
    if( tokenInfo.getId.admin ) {
		get_admin_by_id(tokenInfo.getAuthToken, (tokenInfo.getId.id ? tokenInfo.getId.id : tokenInfo.getId._id) )
		.then( (res) => {
			console.log('Updating Token.');
			//console.log('Updating admin token', res);
			if( res.headers["x-refresh-token"] ) {
				localStorage.setItem('profact-token', res.headers["x-refresh-token"]);
			}
		});
	}else{
		
		get_cfa_by_id(tokenInfo.getAuthToken, (tokenInfo.getId.id ? tokenInfo.getId.id : tokenInfo.getId._id) )
		.then( (res) => {
			//console.log('Updating cfa token', res);
			console.log('Updating Token.');
			if( res.headers["x-refresh-token"] ) {
				localStorage.setItem('profact-token', res.headers["x-refresh-token"]);
			}
		});
	}
}
export const CHECK_LOGIN_STATUS = async (tokenInfo, setUserState) =>{
	//console.log('Checking Login status...');
    if( tokenInfo.getId.admin ) {

		get_admin_by_id(tokenInfo.getAuthToken, (tokenInfo.getId.id ? tokenInfo.getId.id : tokenInfo.getId._id) )
		.then( (res) => {
		  //console.log('Retrieving the user\'s current login information...', res);
		  
		  if( res.status && res.status !== 200 ) {
	
			throw res; 
		  }
	
		  if( res.data ) {
			//console.log('Here is the data form the login: ', res.data, getAuthToken);
	
			//localStorage.setItem('profact-token', JSON.stringify(myUser));
			setUserState({
			  loggedIn: true,
			  refreshData: false,
			  lastRefreshPage: window.location.pathname,
			  //preferredLanguage: 'en-US',
			  preferredLanguage: 'en-US',
			  user:{
				...res.data,
				userType: (tokenInfo.getId.admin ? 'A' : 'C')
			  }
			});
	
			//console.log('Done checking User state.');
		  }
		} )
		.catch( error => {
		  if( error.statusCode && ( error.statusCode === 500 ||  error.statusCode === 404 ) ) {
			console.error('Could not fetch user\'s current login information... ');
		  }
		});
	  }else{
  
		get_cfa_by_id(tokenInfo.getAuthToken, (tokenInfo.getId.id ? tokenInfo.getId.id : tokenInfo.getId._id) )
		.then( (res) => {
		  //console.log('Retrieving the user\'s current login information...', res);
		  
		  if( res.status && res.status !== 200 ) {
	
			throw res; 
		  }
	
		  if( res.data ) {
			//console.log('Here is the data form the login: ', res.data, getAuthToken);
	
			//localStorage.setItem('profact-token', JSON.stringify(myUser));
			setUserState({
			  loggedIn: true,
			  refreshData: false,
			  lastRefreshPage: window.location.pathname,
			  //preferredLanguage: 'en-US',
			  preferredLanguage: 'en-US',
			  user:{
				...res.data,
				userType: (tokenInfo.getId.admin ? 'A' : 'C')
			  }
			});
	
			//console.log('Done checking User state.');
		  }
		} )
		.catch( error => {
		  if( error.statusCode && ( error.statusCode === 500 ||  error.statusCode === 404 ) ) {
			console.error('Could not fetch user\'s current login information... ');
		  }
		});
	  }
}