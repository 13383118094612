import React, {useState, useEffect, 
//	useRef
} from 'react';

//import Config from '../Config';
import moment from 'moment-timezone';
import "react-datepicker/dist/react-datepicker.css";
//import parser from 'html-react-parser';

import { 
	//AUTO_update_news,
	//get_specific_news,
	//delete_news,
	admin_delete_pay_form,
	payment_all_history,
	DECODE_TOKEN 
} from '../Api/api';

import ApplyPaymentForm from './Components/PayComponents/ApplyPaymentForm';
//import PaymentHistoryTable from './Components/PayComponents/PaymentHistoryTable';

const AdminManagePaymentsPage = (props) =>{
	//const userState = props.UserState;
	document.title = props.title;
	//Aux functions


	
	const [currentSubpage, SetCurrentSubpage] = useState('payment-history');
	const [existingPayments, SetExistingPayments] = useState({
		isLoading: true,
		isError: false,
		payments: []
	});
	
	const [payDetails, SetPayDetails] = useState({
		id: null,
		userId: '',
		payConf: '',
		poNumber: '',
		invoiceNumber: '',
		payMethod: '',
		payPurpose: '',
		payAmount: '',
		payNotes: '',
		cart: [],
		paid: 0 
	});

	const [tablePagination, SetTablePagination] = useState({
		tablePage: 0,
		count: 50
	});

	//Payment Table Filter
	const [filterPaymentText, FilterPaymentByText] = useState( '' );
	const [showAllPayments, SetShowAllPayments] = useState(0);
	

	useEffect( ()=> {
		GetExistingPayments()
	}, [] );
	
	useEffect( ()=> {
		FilterPaymentByText(filterPaymentText)
	}, [filterPaymentText] );

	const handleShowAllPayments = (setAllPaymentsTo) => {
		SetShowAllPayments(setAllPaymentsTo);
		SetTablePagination({
			...tablePagination,
			tablePage: 0
		});
	}
	
	const handleFilterOnChange = event => {
		FilterPaymentByText(event.target.value);
		SetTablePagination({
			...tablePagination,
			tablePage: 0
		});
	};

	const getCartDate = (cartItems) => {
		if( !cartItems ) {
			return 'N/A';
		}
		const myShoppingCart =  parseCart(cartItems); //JSON.parse(cartItems);
		if( myShoppingCart.originalDate ) {
			return myShoppingCart.originalDate;
		}

		return null;
	}
	
	const getCartItems = (payPurpose, cartItems) => {
		if( !cartItems ) {
			return 'N/A';
		}
		//console.log('My Cart items?', cartItems);
		const myShoppingCart =  parseCart(cartItems); //JSON.parse(cartItems);


		const myCart = (
			myShoppingCart.items ?
			myShoppingCart.items
			: myShoppingCart
		);

//OLD ID
		if( myCart && myCart.orderId ){
			return `Old Order: ${myCart.orderId}`;
		}
		if( myCart && (myCart.name || myCart.phone) ){
			const myInfo = [];
			if( myCart.name ) { myInfo.push( myCart.name) }
			if( myCart.phone ) { myInfo.push( 'Ph: ' + myCart.phone) }
			return `${myInfo.join('; ')}`;
		}


		//console.log(myCart);
		if( payPurpose.toLowerCase() === 'exam' ) {
			if( myCart[0] ) {
				return `CFA Exam ($${myCart[0].amount})`;
			}
			return `CFA Exam Purchase`;
		}else if( payPurpose.toLowerCase() === 'tfa' ) {
			let numTfas = 0;
			let tfaCost= 0.0;
			if( myCart.forEach) {
				
				myCart.forEach((tfa) => {
					numTfas++;
					tfaCost += tfa.amount;
				});
				return `${numTfas} TFAs for $${tfaCost}`;
			}
			return `TFA Purchase`;
		}else if( payPurpose.toLowerCase() === 'course' ) {
			if( myCart[0] ) {
				return `CE Course "${myCart[0].title}" ($${myCart[0].amount})`;
			}
			return `CE Course Purchase`;
		}else if( payPurpose.toLowerCase() === 'renewal' ) {
			//let numRenews = 0;
			//let renewCost= 0.0;
			if( myCart.forEach) {
				const renewals = [];
				myCart.forEach((renew) => {
					if( !renew.year ) { return; }
					//numRenews++; 
					//renewCost += renew.amount;
					renewals.push( renew.year );
				});
				return `Renewals: ${renewals.join(', ')}`;
			}
			return `Renewal Purchase`;
		}


		return '';
	}

	const GetExistingPayments = () =>{
		
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return false;
		}

		payment_all_history( tokenInfo.getAuthToken )
			.then( (res) => {
				//console.log('Retrieving payments from database.', res);
				if( res.status && res.status !== 200 ) {
					throw res; 
				}

				if( res.data.code !== 'OK' ) {
					throw res;
				}
				SetExistingPayments({
					isLoading: false,
					isError: false,
					payments: res.data.paymentHistory
				});
				//console.log('Done!')
				//setNewsItems( res.data );
				//setNewsItems(data);
			} )
			.catch( error => {

				if( error.statusCode && error.statusCode === 500) {
					console.error('Could not fetch news items. ');
				}
				SetExistingPayments({
					isLoading: true,
					isError: false,
					payments: []
				});
				//setNewsError(true);
			});
	}


	const _GetTablePagination = () => {
		const myCount = existingPayments.payments.filter( (paymentObj) => {
			let showMe = true;
			//console.log('payment: ', paymentObj);
			/*
			
					<button className={
							`button small primary ${showAllPayments === 1? 'is-selected' : ''}`
						} onClick={(e) =>{ handleShowAllPayments( 1 ) }}>Show All Payments</button>
					<button className={
							`button small primary ${showAllPayments === 0? '' : 'is-selected'}`
						} onClick={(e) =>{ handleShowAllPayments( 0 ) }}>Show Unapproved / Pending Payments</button>
						<button className={
								`button small primary ${showAllPayments === 2? '' : 'is-selected'}`
							} onClick={(e) =>{ handleShowAllPayments( 2 ) }}> Show Completed Payments Only</button>
			*/
			if( showAllPayments===0 ) {
				showMe = showMe && (!paymentObj.paid);
			}else if( showAllPayments===1 ) {
				showMe = showMe && true;
			}else if( showAllPayments===2 ) {
				showMe = showMe && (paymentObj.paid);
			}

			if( filterPaymentText !== '' ) {
				const myFilterPaymentText = filterPaymentText.toLocaleLowerCase().trim();
				//console.log(paymentObj.user);
				showMe = showMe && (
					paymentObj.payConf.toLocaleLowerCase().includes( myFilterPaymentText )
					|| paymentObj.id.toString().includes( myFilterPaymentText )
					|| (
						paymentObj.user ?
						( 
							paymentObj.user.firstName.toLocaleLowerCase().includes( myFilterPaymentText ) 
							|| paymentObj.user.lastName.toLocaleLowerCase().includes( myFilterPaymentText ) 
						)
						: false
					)
					|| (paymentObj.invoiceNumber ? paymentObj.invoiceNumber.toLocaleLowerCase().includes( myFilterPaymentText ) : false )
					|| (paymentObj.poNumber ? paymentObj.poNumber.toLocaleLowerCase().includes( myFilterPaymentText ): false )
					|| (paymentObj.payPurpose ? paymentObj.payPurpose.toLocaleLowerCase().includes( myFilterPaymentText ): false )
					|| (paymentObj.payMethod ? paymentObj.payMethod.toLocaleLowerCase().includes( myFilterPaymentText ): false )
				);

			}


			return showMe;
		}).length;

		const maxPages = Math.ceil( myCount / tablePagination.count);
		/*
		console.log('Pagination: ', {
			listCount: myCount,
			tablePage: tablePagination.tablePage,
			count: tablePagination.count,
			maxPages
		});*/

		if( maxPages < 2 ) {
			return (<></>);
		}
		const getPageBtns = [];

		let trimFrontPages = false;
		let trimBackPages = false;

		if( maxPages > 10 ) {
			if(tablePagination.tablePage < 5 ) {
				trimBackPages =true;
				for(let i=0;i<6;i++){
					getPageBtns.push( 
						<li className={(tablePagination.tablePage === (i) ? 'current' : '')}>
							<a href="/#" aria-label={"Page "+ (i+1)} onClick={(e) => { 
										e.preventDefault();  
										e.stopPropagation(); 
										SetTablePagination({
											...tablePagination,
											tablePage: i
										});
										return false; 
									}}>
								<span className="show-for-sr">You're on table page</span> {i+1} 
							</a>
						</li>
					);
				}
			}else if(tablePagination.tablePage > maxPages-6 ) {
				trimFrontPages =true;
				for(let i=maxPages-6;i<maxPages;i++){
					getPageBtns.push( 
						<li className={(tablePagination.tablePage === (i) ? 'current' : '')}>
							<a href="/#" aria-label={"Page "+ (i+1)} onClick={(e) => { 
										e.preventDefault();  
										e.stopPropagation(); 
										SetTablePagination({
											...tablePagination,
											tablePage: i
										});
										return false; 
									}}>
								<span className="show-for-sr">You're on table page</span> {i+1} 
							</a>
						</li>
					);
				}
			}else{
				trimBackPages =true;
				trimFrontPages =true;
				for(let i=tablePagination.tablePage-2;i<tablePagination.tablePage+3;i++){
					getPageBtns.push( 
						<li className={(tablePagination.tablePage === (i) ? 'current' : '')}>
							<a href="/#" aria-label={"Page "+ (i+1)} onClick={(e) => { 
										e.preventDefault();  
										e.stopPropagation(); 
										SetTablePagination({
											...tablePagination,
											tablePage: i
										});
										return false; 
									}}>
								<span className="show-for-sr">You're on table page</span> {i+1} 
							</a>
						</li>
					);
				}
			}
		}else{
			for(let i=0;i<maxPages;i++){
				getPageBtns.push( 
					<li className={(tablePagination.tablePage === (i) ? 'current' : '')}>
						<a href="/#" aria-label={"Page "+ (i+1)} onClick={(e) => { 
									e.preventDefault();  
									e.stopPropagation(); 
									SetTablePagination({
										...tablePagination,
										tablePage: i
									});
									return false; 
								}}>
							<span className="show-for-sr">You're on table page</span> {i+1} 
						</a>
					</li>
				);
			}
		}

		return (
			<nav aria-label="Table Pagination">
				<ul className="pagination">{
						tablePagination.tablePage < 1 ? 
						<li className={"pagination-previous disabled"}>Previous <span className="show-for-sr">page</span></li>
						: <li className={"pagination-previous"}>
							<a href="/#" aria-label="Previous page" onClick={(e) => { 
								e.preventDefault();  
								e.stopPropagation(); 
								SetTablePagination({
									...tablePagination,
									tablePage: tablePagination.tablePage-1
								});
								return false; 
							} }>Previous <span className="show-for-sr">page</span> </a>
						</li>
					}
					{trimFrontPages ? <>
						<li className={(tablePagination.tablePage === (0) ? 'current' : '')}>
							<a href="/#" aria-label={"Page "+ (0+1)} onClick={(e) => { 
										e.preventDefault();  
										e.stopPropagation(); 
										SetTablePagination({
											...tablePagination,
											tablePage: 0
										});
										return false; 
									}}>
								<span className="show-for-sr">You're on table page</span> {0+1} 
							</a>
						</li>
						<li className={"disabled"}>... <span className="show-for-sr">trimmed front pages</span></li> 
					</>: <></>}
					{getPageBtns}
					{trimBackPages ? <>
						<li className={"disabled"}>... <span className="show-for-sr">trimmed back pages</span></li> 
						<li className={(tablePagination.tablePage === (maxPages-1) ? 'current' : '')}>
							<a href="/#" aria-label={"Page "+ (maxPages)} onClick={(e) => { 
										e.preventDefault();  
										e.stopPropagation(); 
										SetTablePagination({
											...tablePagination,
											tablePage: maxPages-1
										});
										return false; 
									}}>
								<span className="show-for-sr">You're on table page</span> {maxPages} 
							</a>
						</li>
					</> : <></>}
					{
						tablePagination.tablePage >= maxPages-1 ? 
						<li className={"pagination-next disabled"}>Next <span className="show-for-sr">page</span></li>
						: <li className={"pagination-next"}>
							<a href="/#" aria-label="Next page" onClick={(e) => { 
								e.preventDefault(); 
								e.stopPropagation(); 
								SetTablePagination({
									...tablePagination,
									tablePage: tablePagination.tablePage + 1
								});
								return false; 
							}}>Next <span className="show-for-sr">page</span> </a>
						</li>
					}
				</ul>
			</nav>
		);
	}

	const OutputPaymentHistory = () => {
		/*
		{
			existingPayments.isLoading 
			? <tr><td colSpan="10"><i className="fas fa-circle-notch fa-spin"></i> Loading</td></tr>
			: (
				existingPayments.isError
				? <tr><td colSpan="10"><i className="fas fa-exclamation-triangle"></i> There was a problem fetching the payment history of users. </td></tr>
				: null //_GetFilteredNews()
			)
		}
		*/
		//console.log('Ouputting payment history', existingPayments);
		if( existingPayments.isLoading ) {
			return (
				<tr><td colSpan="13"><i className="fas fa-circle-notch fa-spin"></i></td></tr>
			);
		}
		if( existingPayments.isError ) {
			return (
				<tr><td colSpan="13">Could not retrieve your payment history.</td></tr>
			);
		}

		if( existingPayments.payments.length < 1 ) {
			return (
				<tr><td colSpan="13">No Payment History yet.</td></tr>
			);
		}
		return existingPayments.payments.filter( (paymentObj) => {
				let showMe = true;
				//console.log('payment: ', paymentObj);
				/*
				if( !showAllPayments ) {
					showMe = showMe && (!paymentObj.paid);
				}*/
				if( showAllPayments===0 ) {
					showMe = showMe && (!paymentObj.paid);
				}else if( showAllPayments===1 ) {
					showMe = showMe && true;
				}else if( showAllPayments===2 ) {
					showMe = showMe && (paymentObj.paid);
				}

				if( filterPaymentText !== '' ) {
					const myFilterPaymentText = filterPaymentText.toLocaleLowerCase().trim();
					//console.log(paymentObj.user);
					showMe = showMe && (
						paymentObj.payConf.toLocaleLowerCase().includes( myFilterPaymentText )
						|| paymentObj.id.toString().includes( myFilterPaymentText )
						|| (
							paymentObj.user ?
							( 
								paymentObj.user.firstName.toLocaleLowerCase().includes( myFilterPaymentText ) 
								|| paymentObj.user.lastName.toLocaleLowerCase().includes( myFilterPaymentText ) 
							)
							: false
						)
						|| (paymentObj.invoiceNumber ? paymentObj.invoiceNumber.toLocaleLowerCase().includes( myFilterPaymentText ) : false )
						|| (paymentObj.poNumber ? paymentObj.poNumber.toLocaleLowerCase().includes( myFilterPaymentText ): false )
						|| (paymentObj.payPurpose ? paymentObj.payPurpose.toLocaleLowerCase().includes( myFilterPaymentText ): false )
						|| (paymentObj.payMethod ? paymentObj.payMethod.toLocaleLowerCase().includes( myFilterPaymentText ): false )
					);

				}


				return showMe;
			})
			.slice(
				tablePagination.tablePage * tablePagination.count,
				(tablePagination.tablePage * tablePagination.count) + tablePagination.count
			).map( (paymentObj, key) => {
				if( paymentObj.id === 23350) {
					console.log(paymentObj);
				}

				const paymentDetails = getCartItems(paymentObj.payPurpose, paymentObj.cart);
				const detailsProp = {};
				if( paymentDetails && paymentDetails.startsWith('Old Order')) {
					detailsProp.style={wordBreak:'break-all'};
				} 
			return (
				<tr key={key}>
					<td>{paymentObj.id}</td>
					<td>{
						paymentObj.user ? 
						<>{paymentObj.user.firstName} {paymentObj.user.lastName}</>
						: 'N/A'
					}
					</td>
					<td>{paymentObj.payPurpose}</td>
					<td>{paymentObj.payConf}</td>
					<td>{paymentObj.invoiceNumber}</td>
					<td>{paymentObj.poNumber}</td>
					<td>{paymentObj.payAmount.toLocaleString('en-US', {
						style: 'currency',
						currency: 'USD',
						})}</td>
					{paymentObj.paid ? <td>Yes</td> : (<td style={{backgroundColor:'lightgray'}}><strong>No</strong></td>) }
					<td>{
							getCartDate(paymentObj.cart ) ||
							moment.tz(paymentObj.updatedAt,'YYYY-MM-DDTHH:mm:ss.SSSZ', 'Etc/UTC')
							.tz( moment.tz.guess() )
							.format('L LT')
					}</td>
					<td {...detailsProp} >{paymentDetails ? paymentDetails : 'N/A'}</td>
					{
						paymentObj.paid ?
						<td></td>
						: <td>
								<button className="button warning small no-bottom-margin override-sebs"
									onClick={ (e) => { GetPaymentInformation(paymentObj.id, true) } }
								><i className="fa fa-pencil-alt"></i></button>
							</td>
					}
					
					{
						/* Issue #5; If this is specifically a method "eCheck", then we display the delete button always? */	
						paymentObj.paid && paymentObj.payMethod !== 'eCheck' ?
						<td></td>
						: <td>{
							<button className="button alert small no-bottom-margin"
								onClick={ (e) => { GetPaymentInformation(paymentObj.id, false) } }
							><i className="fa fa-trash"></i> </button>
						}</td>
					}
				</tr>
			);
		});
	}

	const parseCart = (cartInfo) => {
		try{
			return JSON.parse(cartInfo);
		} catch (objError) {
			return {"items":[],"comments":"Error loading cart."};
		}
	}
	
	const GetPaymentInformation = (payId, forApproval = true) => {
		//Find the pay id in the history
		const findPayment = existingPayments.payments.find( ( payInfo ) => { return payInfo.id === payId; } );

		//console.log(findPayment);
		const myCart = parseCart(findPayment.cart); //JSON.parse(findPayment.cart || `{"items":[],"comments":"N/A"}`);


		if( forApproval ) {
			SetPayDetails( {
				...findPayment,
				payNotes: (myCart && myCart.comments ? myCart.comments : ''),
				invoiceNumber: findPayment.invoiceNumber || '',
				poNumber: findPayment.poNumber || '',
				cart: myCart
			});
			SetCurrentSubpage('payment-form');
		}else{
			SetPayDetails( {
				...findPayment,
				payNotes: (myCart && myCart.comments ? myCart.comments : ''),
				invoiceNumber: findPayment.invoiceNumber || '',
				poNumber: findPayment.poNumber || '',
			});
			SetCurrentSubpage('delete-payment-form');
			//setProfileForm( 'delete-pay-form' ); 
		}

		return <></>;
	}

	const ExistingPaymentHistory = () =>{
		

		return (
			<>
			<div style={{display: ( currentSubpage!=='payment-history' ? 'none' : 'block' )}}>
				<h3> Payment History Table </h3>
				<div>
					<h4>Search Payments</h4>
					<div className="row">
						<div className="medium-3 small-12 columns">
							<label htmlFor="middle-label" className="label-style-1">Filter By Text: </label>
						</div>
						<div className="medium-5 small-12 no-float columns">
							<input type="text" id="middle-label" placeholder="ID, Purpose, Conf Number, PO / Invoice Number, etc." 
								onChange={handleFilterOnChange}
							/>
						</div>
					</div>
					<div className="row">
						<div className="medium-3 small-12 columns" >
							<label htmlFor="tablePaginationRows"
								className="label-style-1" > Number of Payments Per Page: </label> 
						</div> 
						<div className="medium-5 small-12 no-float columns" >
							<select 
								id="tablePaginationRows"
								name="tablePaginationRows"
								value={tablePagination.count}
								onChange={e => { 
									SetTablePagination({
										...tablePagination,
										tablePage:0,
										count: Number(e.target.value)
									});
								} }>
									<option value="10">10</option>
									<option value="25">25</option>
									<option value="50">50</option>
									<option value="100">100</option>
									<option value="200">200</option>
							</select> 
						</div> 
					</div>
				</div>
				<div className="button-group stacked-for-small button-group-override">
					<button className={
							`button small primary ${showAllPayments === 1? 'is-selected' : ''}`
						} onClick={(e) =>{ handleShowAllPayments( 1 ) }}>Show All Payments</button>
					<button className={
							`button small primary ${showAllPayments === 0? 'is-selected' : ''}`
						} onClick={(e) =>{ handleShowAllPayments( 0 ) }}>Show Unapproved / Pending Payments</button>
						<button className={
								`button small primary ${showAllPayments === 2? 'is-selected' : ''}`
							} onClick={(e) =>{ handleShowAllPayments( 2 ) }}> Show Completed Payments Only</button>
				</div>
				{_GetTablePagination()}
				<div className="table-scroll">
					
					<table className="table-left-align-all">
						<thead>
							<tr>
								<th>Id</th>
								<th>CFA</th>
								<th>Purpose</th>
								<th>Conf. No. </th>
								<th>Invoice</th>
								<th>PO Number</th>
								<th>Cost</th>
								<th>Paid / Approved</th>
								<th>Date</th>
								<th>Details</th>
								<th>Update</th>
								<th>Delete</th>
							</tr>
						</thead>
						<tbody>
							{OutputPaymentHistory()}
						</tbody>
					</table>
				</div>
				{_GetTablePagination()}
			</div>


			</>
		)
	}
	const confirmUpdatePayment = () => {
		/*
		SetUpdatePaymentItem({
			id: '',
		});*/
		SetPayDetails({
			id: null,
			userId: '',
			payConf: '',
			poNumber: '',
			invoiceNumber: '',
			payMethod: '',
			payPurpose: '',
			payAmount: '',
			payNotes: '',
			cart: [],
			paid: 0 
		});
		SetCurrentSubpage('payment-history');
		GetExistingPayments()
	}
	const cancelUpdatePayment = () => {
		/*
		SetUpdatePaymentItem({
			id: '',
		});
		*/
		
		SetPayDetails({
			id: null,
			userId: '',
			payConf: '',
			poNumber: '',
			invoiceNumber: '',
			payMethod: '',
			payPurpose: '',
			payAmount: '',
			payNotes: '',
			cart: [],
			paid: 0 
		});
		SetCurrentSubpage('payment-history');
		GetExistingPayments()
	}
	
	const deletePayItem = () => {
		
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return false;
		}

		setDisableDelPayBtn(true);

		admin_delete_pay_form( tokenInfo.getAuthToken, payDetails)
			.then( (res) => {
				console.log('Removed Pay details', res);
				SetCurrentSubpage('payment-history');
				GetExistingPayments();
				SetPayDetails({
					id: null,
					userId: '',
					payConf: '',
					poNumber: '',
					invoiceNumber: '',
					payMethod: '',
					payPurpose: '',
					payAmount: '',
					payNotes: '',
					cart: [],
					paid: 0 
				});
				setDisableDelPayBtn(false);

			} )
			.catch( error => {
				console.error('Updating payment [submitPayInfo] Failed.', error);
				if( error.statusCode && error.statusCode === 500) {
					console.error('Could not add/update the payment for this CFA.');
				}
				setDisableDelPayBtn(false);
			});
			
	}
	const [disableDelPayBtn, setDisableDelPayBtn] = useState(false);
	const DeletePayForm = () => {
/*

		userId: userId,
		payConf: '',
		poNumber: '',
		invoiceNumber: '',
		payMethod: '',
		payPurpose: '',
		payAmount: '',
		payNotes: '',
		cart: [],
		paid: 0 
*/
		return (
			<div>
				<h5> Payment Information: </h5>
				{
					payDetails.paid && (payDetails.payMethod === 'eCheck' || payDetails.payMethod.toLowerCase() === 'echeck')
					? 
					<div class="callout warning">
						<h5>Notice</h5>
						<p>
							If you remove / revoke this payment, please remember to revert the changes made by this transaction manually for this user.
							You may want to print this page for your records.
						</p>
					</div>
					:null
				}
				<div>
					<dl>
						<dt>Confirmation Number:</dt>
							<dd>{payDetails.payConf}</dd>
						<dt>Pay Method:</dt>
							<dd>{payDetails.payMethod}</dd>
						<dt>Pay Purpose:</dt>
							<dd>{payDetails.payPurpose}</dd>
						<dt>PO Number if available:</dt>
							<dd>{payDetails.poNumber}</dd>
						<dt>Invoice Number if available:</dt>
							<dd>{payDetails.invoiceNumber}</dd>
						<dt>Amount:</dt>
							<dd>{payDetails.payAmount.toLocaleString('en-US', {
						style: 'currency',
						currency: 'USD',
						})}</dd>
						<dt>Item Summary:</dt>
							<dd>{getCartItems(payDetails.payPurpose,payDetails.cart)}</dd>
						<dt>Comments</dt>
							<dd>
								<p className="no-bottom-margin">{payDetails.payNotes}</p>
							</dd>
					</dl>
				</div>
				<h5> <strong>NOTICE: </strong> This action cannot be undone.</h5>
				<div>
					<button onClick={ (e) => { 
							cancelUpdatePayment();
						} } 
						className="override-sebs button primary success" type="button">
						Cancel (DO NOT DELETE) </button>  <button onClick={ (e) => { 
							//UpdateTfaFormData(STOCK_TFA); toggleShowContent('tfa-list'); 
							deletePayItem();
						} } 
						className="button primary alert" disabled={disableDelPayBtn} type="button">
						<i className="fas fa-trash"></i> REMOVE PENDING PAYMENT FROM CFA </button>
				</div>
			</div>
		);
	
	}
	const ShowProperModule = () => {
		if( currentSubpage==='payment-history' ) {
			return ExistingPaymentHistory();
		}else if( 
			currentSubpage==='payment-form' 
		){
			return (
				<ApplyPaymentForm initialPayDetails={payDetails} cancelUpdatePayment={cancelUpdatePayment} confirmUpdatePayment={confirmUpdatePayment} />
			)
		}else if( 
			currentSubpage==='payment-form-new' 
		) {
			return (
				<ApplyPaymentForm initialPayDetails={{
					id: null,
					userId: '',
					payConf: '',
					poNumber: '',
					invoiceNumber: '',
					payMethod: '',
					payPurpose: '',
					payAmount: '',
					payNotes: '',
					cart: [],
					paid: 0 
				}} cancelUpdatePayment={cancelUpdatePayment} confirmUpdatePayment={confirmUpdatePayment} />
			)
		}else if( currentSubpage==='delete-payment-form' ) {
			return DeletePayForm()
		}
	}
	/*

					{ExistingPaymentHistory()}
					{PaymentForm()}
					*/
	return (
		<main id="main-wrapper" className=" ">
			<div id="main" className="">
				<div id="main-col" className="">
					<div id="content">
						<h2> Manage User Payments </h2>
						<p><em>You can update a CFA&rsquo;s PO / Invoice / Check information or apply </em></p>
						<div className="button-group stacked-for-small button-group-override">
							<button className={
								`button small primary ${currentSubpage === 'payment-history' ? 'is-selected' : ''}`
							} onClick={(e) =>{ SetCurrentSubpage( 'payment-history' ) }}>Payment History</button>
							<button className={
								`button small primary ${currentSubpage === 'payment-form-new' ? 'is-selected' : ''}`
							} onClick={(e) =>{ 
								SetPayDetails({
									id: null,
									userId: '',
									payConf: '',
									poNumber: '',
									invoiceNumber: '',
									payMethod: '',
									payPurpose: '',
									payAmount: '',
									payNotes: '',
									cart: [],
									paid: 0 
								});
								SetCurrentSubpage( 'payment-form-new' ) }}>Add New Payment Item</button>
						</div>
						{ShowProperModule()}
					</div>
				</div>
			</div>
		</main>
	);
}
export default AdminManagePaymentsPage;