import React, {
	useState, 
	useEffect
	//createRef
} from 'react';

import ProfactSelect from '../../../HelperComponents/ProfactSelect';

import { 
	//CFA Profile
	update_cfa_profile,
	update_contact_info,
	COMBO_business_contact,
	get_contact_info,
	get_tfas_by_cfas,
	delete_tfa_to_cfa,
	AUTO_tfa_to_cfa,

	
	payment_cfa_history,
	AUTO_admin_payment,
	admin_delete_pay_form,

	
	get_ce_courses_and_cfa_credits,
	admin_apply_single_course_credit,
	register_for_ce_course,

	merge_accounts,

	DECODE_TOKEN 
} from '../../../Api/api';

import moment from 'moment-timezone';

import {FormatPhoneNumber, FormatZipcode} from '../../../Config';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";


const EditCFARecord = (props) => {
	
	//these items are functions to the parent page.
	const [
		cancelEditing,
		confirmEditing
	] = [
		props.cancelEditing, 
		props.confirmEditing 
	];
	const currentYear = new Date().getFullYear() ;
	
	const selectedUser = props.selectedUser;
	const userId = selectedUser.id;

	
	//tabs
	const [profileForm, setProfileForm] = useState('personal');

	//console.log(selectedUser);

	//Items by Tab Form
	
	const [disableSubmitBtn, SetDisableSubmitBtn] = useState(false);
	const disableTrainingCompleted = (selectedUser.trainingCompleted===true || selectedUser.trainingCompleted==='true' || selectedUser.trainingCompleted === "1" || selectedUser.trainingCompleted === 1 ? true : false);
	const [personalInfo, setPersonalInfo] = useState( {
		id: userId,
		firstName: selectedUser.firstName,
		lastName: selectedUser.lastName,
		email: selectedUser.email,
		profactId: selectedUser.profactId,
		examPaid: (selectedUser.examPaid===true || selectedUser.examPaid==='true' || selectedUser.examPaid === "1" || selectedUser.examPaid === 1 ? 1 : 0),
		examPassed: (selectedUser.examPassed===true || selectedUser.examPassed==='true' || selectedUser.examPassed === "1" || selectedUser.examPassed === 1 ? 1 : 0),
		trainingCompleted: (selectedUser.trainingCompleted===true || selectedUser.trainingCompleted==='true' || selectedUser.trainingCompleted === "1" || selectedUser.trainingCompleted === 1 ? 1 : 0),
		
		spanishPref: (selectedUser.spanishPref === "1" || selectedUser.spanishPref === 1 ? 1 : 0),
		activeYear: selectedUser.activeYear || '',
		cycleYear: selectedUser.cycleYear || '',
		certDate: (selectedUser.certDate ? moment(selectedUser.certDate).tz( moment.tz.guess() ).toDate() : null),
		//credits: selectedUser.credits || 0
	} );

	const [mergeInfo, SetMergeInfo] = useState({
		oldId: '',
		cfaId: userId,
		confirm: false
	});
	const [disableMergeBtn, SetDisableMergeBtn] = useState(false);

	////console.log('??????????', selectedUser);
	////console.log('??????????', personalInfo);


	const [mergeAlert, setMergeAlert] = useState({
		type: 'success',
		display: 'none',
		title: '',
		message: ''
	});
	const [personalAlert, setPersonalAlert] = useState({
		type: 'success',
		display: 'none',
		title: '',
		message: ''
	});
	
	const personalForm_HandleChange = (e_target) => {
		const { name, value } = e_target;
		////console.log(e_target, );
        setPersonalInfo(personalInfo => ({
            ...personalInfo,
            [name]: value
		}));
	}
	
	const personalForm_changeDate_HandleChange = (date) => {
		setPersonalInfo(personalInfo => ({
			...personalInfo,
			certDate: date
		}));
	}
	const personalForm_enableExam_HandleChange = (e_target) => {
		////console.log(e_target, [e_target.name, e_target.checked]);
		const { name, checked } = e_target;
        setPersonalInfo(personalInfo => ({
            ...personalInfo,
            [name]: (checked ? 1 : 0)
		}));
	}
	const personalForm_spanishPref_HandleChange = (e_target) => {
		////console.log(e_target, [e_target.name, e_target.checked]);
		const { name, checked } = e_target;
        setPersonalInfo(personalInfo => ({
            ...personalInfo,
            [name]: (checked ? 1 : 0)
		}));
	}

	const handlePersonalDetailsEvent = (e) => {
		e.preventDefault();

		//console.log('Submitting this CFAs personal details: ',  personalInfo);
		
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return () => {}; 
		}

		

		SetDisableSubmitBtn(true);
		update_cfa_profile( tokenInfo.getAuthToken, {
			...personalInfo ,
			activeYear: (personalInfo.activeYear || null ),
			cycleYear: (personalInfo.cycleYear || null )
			
		})
			.then((response) =>{
				//console.log('Updating this current user.', response);
				
				setPersonalAlert({
					type: 'success',
					display:'block', 
					title: 'Successfully updated!',
					message: 'We have saved this cfa\'s personal information.'	
				});

				SetDisableSubmitBtn(false);
				document.getElementById('personal-details-div').scrollIntoView();
			})
			.catch((error) => {
				console.log('AAAAAAAAAAAAAAAAAA');
				console.log(error.response);
				console.error('Failed to update this user\'s home details.', error,
				error.response
				);
				setPersonalAlert({
					type: 'alert',
					display:'block', 
					title: 'Error',
					message: (
						error.response 
						? error.response.message || (
							error.response.data ?
							error.response.data.message : null
						)
						: error.message ) 
					|| error
				});
				document.getElementById('personal-details-div').scrollIntoView();
				SetDisableSubmitBtn(false);
			});
		return false;
	};




	
	const startMerge = () => {

		//console.log('Submitting this CFAs personal details: ',  personalInfo);
		
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return () => {}; 
		}

		SetDisableMergeBtn(true);
		merge_accounts( tokenInfo.getAuthToken,
			mergeInfo.cfaId,
			(''+mergeInfo.oldId).replace(/[^0-9]/g, '')
		)
		.then(function(response){
			//console.log('Updating this current user.', response);
			if( response.data.code === 'OK') {


				setPersonalInfo( {
					id: response.data.cfa.id,
					firstName: response.data.cfa.firstName,
					lastName: response.data.cfa.lastName,
					email: response.data.cfa.email,
					examPaid: (response.data.cfa.examPaid===true
						|| response.data.cfa.examPaid==='true' 
						|| response.data.cfa.examPaid === "1" 
						|| response.data.cfa.examPaid === 1 ? 1 : 0
					),
					examPassed: (
						response.data.cfa.examPassed===true 
						|| response.data.cfa.examPassed==='true' 
						|| response.data.cfa.examPassed === "1" 
						|| response.data.cfa.examPassed === 1 ? 1 : 0
					),
					trainingCompleted: (
						response.data.cfa.trainingCompleted===true 
						|| response.data.cfa.trainingCompleted==='true' 
						|| response.data.cfa.trainingCompleted === "1" 
						|| response.data.cfa.trainingCompleted === 1 ? 1 : 0
					),
					
					spanishPref: (
						response.data.cfa.spanishPref === "1" 
						|| response.data.cfa.spanishPref === 1 ? 1 : 0),
					activeYear: response.data.cfa.activeYear || '',
					cycleYear: response.data.cfa.cycleYear || '',
					certDate: (response.data.cfa.certDate ? 
						moment(response.data.cfa.certDate).tz( moment.tz.guess() ).toDate() : null),
					//credits: selectedUser.credits || 0
				} );

				SetMergeInfo({
					...mergeInfo,
					confirm: false
				});

				refreshTfas(userId);
				refreshPayHistory(userId);
				getCourseList(userId);

				setMergeAlert({
					type: 'success',
					display:'block', 
					title: 'Successfully merged!',
					message: response.data.message // 'We have merged this CFA with the chosen Old ProFACT Id.'	
				});
				SetDisableMergeBtn(false);
				document.getElementById('merge-details-div').scrollIntoView();
			}else{
				throw Error(response);
			}

		})
		.catch(function(error){
			console.error('Failed to update this user\'s home details.', error);
			setMergeAlert({
				type: 'alert',
				display:'block', 
				title: 'Error',
				message: (error.message || error)
			});
			document.getElementById('merge-details-div').scrollIntoView();
			SetDisableMergeBtn(false);
		});
		return false;
	};
	const generateMergeAccountForm = () => {

		return (
			
			<div className=" medium-10 large-8 large-offset-2 medium-offset-1  columns" style={{display: (profileForm==='merge-acct' ? 'block' : 'none')}}>
				<div>
					<div id="merge-details-div">
						<h3>Merge Old ProFACTs Account</h3>
						
						<div className={"callout " + mergeAlert.type} style={{display:mergeAlert.display}} data-closable>
							<h5>{mergeAlert.title}</h5>
							<p>{mergeAlert.message}</p>
							<button className="close-button" aria-label="Dismiss alert" type="button" onClick={(e)=>{
								window.jQuery( window.jQuery(e.currentTarget).closest('div') ).fadeOut('slow', () => {
									setMergeAlert({
										type: 'success',
										display: 'none',
										title: '',
										message: ''
									})
								});
							}}
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>

						<h4> You are about to merge: </h4>
						<h5>This Account: </h5> 
						<ul>
							<li><strong>First Name</strong>: {personalInfo.firstName} </li>
							<li><strong>Last Name</strong>: {personalInfo.lastName} </li>
							<li><strong>Business</strong>: {businessName.businessName} </li>
							<li><strong>Email</strong>: {personalInfo.email} </li>
						</ul>
						<h5> With the following account: </h5>
						<div className="row">
							<div className="medium-3 small-12  columns">
								<label htmlFor="oldId" className="label-style-1">Old Profact ID</label>
							</div>
							<div className="medium-9 small-12  columns">
								<input type="text" value={mergeInfo.oldId} 
									name="oldId" id="oldId"  
									required="required"
									onChange={e => {
										SetMergeInfo({
											...mergeInfo,
											oldId: (''+ e.target.value).trim()
										})
									}} />
							</div>
						</div>
						<h5> <strong>NOTICE: </strong> This action cannot be undone.</h5>
						<div>
							
							<div className="row">
								<div className=" small-12  columns">
									<label htmlFor="confirmMerge" className="label-style-1">
										Confirm Merge
										<input type="checkbox" className="small-margin-left" name="confirmMerge" id="confirmMerge" 
											checked={mergeInfo.confirm}
											disabled={mergeInfo.oldId.length < 1}
											onChange={e => {
												SetMergeInfo({
													...mergeInfo,
													confirm: e.target.checked
												})
											}}
										/>
									</label>
								</div>
								<div className=" small-12  columns text-align-right">
									<button class="button success override-sebs"
										onClick={ (e) =>{ 
											SetMergeInfo({
												oldId: '',
												cfaId: userId,
												confirm: false
											});
										}}
									>Cancel (DO NOT MERGE)
										</button> <button 
									class="button alert override-sebs"
									disabled={!mergeInfo.confirm || disableMergeBtn}
									onClick={() => {
										startMerge();
									}}
									> <i className="fas fa-people-carry"></i> MERGE ACCOUNTS</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
	const generateProfileForm = () => {



		return (
			<div className=" medium-10 large-8 large-offset-2 medium-offset-1  columns" style={{display: (profileForm==='personal' ? 'block' : 'none')}}>
				<div>
			<div id="personal-details-div">
				<h3>Personal Details</h3>
				<div className={"callout " + personalAlert.type} style={{display:personalAlert.display}} data-closable>
					<h5>{personalAlert.title}</h5>
					<p>{personalAlert.message}</p>
					<button className="close-button" aria-label="Dismiss alert" type="button" onClick={(e)=>{
						window.jQuery( window.jQuery(e.currentTarget).closest('div') ).fadeOut('slow', () => {
							setPersonalAlert({
								type: 'success',
								display: 'none',
								title: '',
								message: ''
							})
						});
					}}
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div className="">
					<form action="" method="post" onSubmit={handlePersonalDetailsEvent}>
						<div className="">
							<div className="row">
								<div className="medium-3 small-12  columns">
									<label htmlFor="firstName" className="label-style-1">First Name</label>
								</div>
								<div className="medium-9 small-12  columns">
									<input type="text" value={personalInfo.firstName} 
										name="firstName" id="firstName"  
										required="required"
										onChange={e => personalForm_HandleChange(e.target)} />
								</div>
							</div>
							<div className="row">
								<div className="medium-3 small-12  columns">
									<label htmlFor="lastName" className="label-style-1">Last Name</label>
								</div>
								<div className="medium-9 small-12  columns">
									<input type="text" value={personalInfo.lastName} 
										name="lastName" id="lastName"  
										required="required"
										onChange={e => personalForm_HandleChange(e.target)} />
								</div>
							</div>
							<div className="row">
								<div className="medium-3 small-12  columns">
									<label htmlFor="email" className="label-style-1">Email</label>
								</div>
								<div className="medium-9 small-12  columns">
									<input type="email" value={personalInfo.email} 
										name="email" id="email"  
										required="required"
										onChange={e => personalForm_HandleChange(e.target)} />
									<p><em>You will use this email to log in.</em></p>
								</div>
							</div>
							<div className="row">
								<div className="medium-12 small-12  columns">
									<label htmlFor="trainingCompleted" className="label-style-1">
										Did User read every training section ? 
										<input type="checkbox" className="small-margin-left" name="trainingCompleted" id="trainingCompleted" 
											checked={personalInfo.trainingCompleted}
											onChange={(e)=>{ personalForm_enableExam_HandleChange(e.target); }}
											disabled={disableTrainingCompleted}
										/>
									</label>
									<p><em> Note: Checking this option will let the system know that this person has finished reading all sections required from the CFA Training Manual and will allow them to use the Payment Page. Please be careful when choosing this option.</em></p>
								</div>
							</div>
							<div className="row">
								<div className="medium-12 small-12  columns">
									<label htmlFor="examPaid" className="label-style-1">
										Enable Exam For User? 
										<input type="checkbox" className="small-margin-left" name="examPaid" id="examPaid" 
											checked={personalInfo.examPaid}
											onChange={(e)=>{ personalForm_enableExam_HandleChange(e.target); }}
										/>
									</label>
								</div>
							</div>
							<div className="row">
								<div className="medium-12 small-12  columns">
									<label htmlFor="examPassed" className="label-style-1">
										Did User Pass? 
										<input type="checkbox" className="small-margin-left" name="examPassed" id="examPassed" 
											checked={personalInfo.examPassed}
											onChange={(e)=>{ personalForm_enableExam_HandleChange(e.target); }}
										/>
									</label>
									<p><em> Note: Checking this option will let the system know that this person has passed their exam. Please be careful when checking this option for someone who has not passed their exam officially.</em></p>
								</div>
							</div>
							<div className="row">
								<div className="medium-3 small-12  columns">
									<label htmlFor="profactId" className="label-style-1">Profact ID</label>
								</div>
								<div className="medium-9 small-12  columns">
									<input type="text" value={personalInfo.profactId} 
										name="profactId" id="profactId"  
										onChange={e => personalForm_HandleChange(e.target)} />
									<p><em>Please make sure no one else is using this profact ID. This field will only be used if you marked them as "Passed".</em></p>
								</div>
							</div>
							<div className="row" style={{marginBottom:'1em'}}>
								<div className="medium-3 small-12  columns">
									<label htmlFor="Cert Date" className="label-style-1">Set Cert Date</label>
								</div>
								<div className="medium-9 small-12  columns">
									<DatePicker 
										className="no-bottom-margin"
										selected={personalInfo.certDate} 
										onChange={date => {
											//console.log('Date picker changed: ', date);
											personalForm_changeDate_HandleChange(date)
										}} 
										popperPlacement="top-end"
										required={personalInfo.examPassed}
										isClearable
									/>
								</div>
							</div>
							<div className="row">
								<div className="medium-3 small-12  columns">
									<label htmlFor="activeYear" className="label-style-1">Set Active Year To</label>
								</div>
								<div className="medium-9 small-12  columns">
									<input type="number" value={personalInfo.activeYear} 
										name="activeYear" id="activeYear" 
										required={personalInfo.examPassed}
										min="1980"
										onChange={e => personalForm_HandleChange(e.target)} />
								</div>
							</div>
							<div className="row">
								<div className="medium-3 small-12  columns">
									<label htmlFor="cycleYear" className="label-style-1">Set Cycle Year To</label>
								</div>
								<div className="medium-9 small-12  columns">
									<input type="number" value={personalInfo.cycleYear} 
										name="cycleYear" id="cycleYear"  
										min="1980"
										required={personalInfo.examPassed}
										onChange={e => personalForm_HandleChange(e.target)} />
								</div>
							</div>
							<div className="row">
								<div className="medium-12 small-12  columns">
									<label htmlFor="spanishPref" className="label-style-1">
										Spanish Preferred
										<input type="checkbox" className="small-margin-left" name="spanishPref" id="spanishPref" 
											checked={personalInfo.spanishPref}
											onChange={(e)=>{ personalForm_spanishPref_HandleChange(e.target); }}
										/>
									</label>
								</div>
							</div>
						</div>
						<div>
							<button type="button" className="button"
							onClick={e => {cancelEditing()}} 
							>Cancel</button> <button 
								disabled={disableSubmitBtn}
								type="submit" className="button"
							>Save CFA Personal Details</button>
						</div>
					</form>
				</div>
			</div>
				</div>
			</div>
		);
	}; 


/*
//Will get recalculated, so this is pointless
	<div className="row">
		<div className="medium-3 small-12  columns">
			<label htmlFor="credits" className="label-style-1">Set Credits to: </label>
		</div>
		<div className="medium-9 small-12  columns">
			<input type="number" value={personalInfo.credits} 
				name="credits" id="credits"  
				onChange={e => personalForm_HandleChange(e.target)} />
		</div>
	</div>
*/


	//Home Tab
	const [homeAddressAlert, setHomeAddressAlert] = useState({
		type: 'success',
		display: 'none',
		title: '',
		message: ''
	});

	const [requireHomeCounty, setRequireHomeCounty] = useState( (selectedUser.homeAddress.state.toLowerCase() === 'nj') );
	const [homeAddress, setHomeAddress] = useState( {
		...selectedUser.homeAddress,
		zip: FormatZipcode(selectedUser.homeAddress.zip),
		phone: FormatPhoneNumber(selectedUser.homeAddress.phone)
	} );

	const handleHomeDetailsEvent = (e) => {
		e.preventDefault();

		//console.log('Submitting home details: ',  homeAddress);
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return () => {}; 
		}
		
		SetDisableSubmitBtn(true);
		update_contact_info( tokenInfo.getAuthToken, selectedUser.homeAddressId , {
			...homeAddress,
			zip: (''+homeAddress.zip).replace(/[^0-9]/g, '').substr(0,9),
			phone: (''+homeAddress.phone).replace(/[^0-9]/g, '').substr(0,11),
		 } )
			.then(function(response){
				//console.log('Updating this current user.', response);
				
				setHomeAddressAlert({
					type: 'success',
					display:'block', 
					title: 'Successfully updated!',
					message: 'We have saved this CFA\'s home address information.'	
				});
				SetDisableSubmitBtn(false);
				document.getElementById('home-details-div').scrollIntoView();
			})
			.catch(function(error){
				console.error('Failed to update this user\'s home details.', error);

				setHomeAddressAlert({
					type: 'alert',
					display:'block', 
					title: 'Error',
					message: (error.message || error)
				});
				SetDisableSubmitBtn(false);
			});
		return false;
	};
	const homeForm_requireCounty = (e_target) => {
        setHomeAddress(homeAddress => ({
            ...homeAddress,
            county: ''
		}));

		if (e_target.value.toLowerCase() === 'nj' ) {
			setRequireHomeCounty(true);
		}else{
			setRequireHomeCounty(false);
		}
		
	};


	const homeForm_HandleChange = (e_target) => {
		const { name, value } = e_target;
		
		let updateValue = value;
        setHomeAddress(homeAddress => ({
            ...homeAddress,
            [name]: updateValue
		}));
	}
	const generateHomeForm = () => {
		return (
			<div className="columns medium-10 large-8 large-offset-2 medium-offset-1" style={{display: (profileForm==='home' ? 'block' : 'none')}}>
				<div>
			<div id="home-details-div">
				<h3>Update Home Details</h3>
				<div className={"callout " + homeAddressAlert.type} style={{display:homeAddressAlert.display}} data-closable>
					<h5>{homeAddressAlert.title}</h5>
					<p>{homeAddressAlert.message}</p>
					<button className="close-button" aria-label="Dismiss alert" type="button" onClick={(e)=>{
						window.jQuery( window.jQuery(e.currentTarget).closest('div') ).fadeOut('slow', () => {
							setHomeAddressAlert({
								type: 'success',
								display: 'none',
								title: '',
								message: ''
							})
						});
					}}
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<form action="" method="post" onSubmit={handleHomeDetailsEvent}>
					<div className="">
						<div className="row"> 
							<div className="medium-3 small-12 columns">
								<label htmlFor="hinfo_street" className="label-style-1">Address</label>
							</div>
							<div className="medium-9 small-12 columns">
								<input type="text" value={homeAddress.street} 
									name="street" id="hinfo_street"  
									required="required"
									onChange={e => homeForm_HandleChange(e.target)} />
							</div>
						</div>
						<div className="row"> 
							<div className="medium-3 small-12 columns">
								<label htmlFor="hinfo_city" className="label-style-1">City</label>
							</div>
							<div className="medium-9 small-12 columns">
								<input type="text" value={homeAddress.city} 
									name="city" id="hinfo_city"  
									required="required"
									onChange={e => homeForm_HandleChange(e.target)} />
							</div>
						</div>
						<div className="row"> 
							<div className="medium-3 small-12 columns">
								<label htmlFor="hinfo_state" className="label-style-1">State</label>
							</div>
							<div className="medium-9 small-12 columns">
								<ProfactSelect
									name="state" id="hinfo_state" StateDropdown={true} 
									value={homeAddress.state}
									required="required"
									onChange={e => {
										homeForm_requireCounty(e.target);
										homeForm_HandleChange(e.target);
									} } />
							</div>
						</div>
						<div className="row"> 
							<div style={{display:(requireHomeCounty ? 'block' : 'none')}} className="medium-3 small-12 columns">
								<label htmlFor="hinfo_county" className="label-style-1">NJ County</label>
							</div>
							<div style={{display:(requireHomeCounty ? 'block' : 'none')}} className="medium-9 small-12 columns">
								<ProfactSelect
									name="county" id="hinfo_county" NjCountyDropdown={true}
									value={homeAddress.county}
									required={requireHomeCounty}
									onChange={e => homeForm_HandleChange(e.target)} />
							</div>
						</div>
						<div className="row"> 
							<div className="medium-3 small-12 columns">
								<label htmlFor="hinfo_zip" className="label-style-1">Zipcode</label>
							</div>
							<div className="medium-9 small-12 columns">
								<input type="text" value={homeAddress.zip} 
									name="zip" id="hinfo_zip"
									minLength="5"
									maxLength="10"
									required="required"
									onChange={e => homeForm_HandleChange(e.target)} />
							</div>
						</div>
						<div className="row"> 
							<div className="medium-3 small-12 columns">
								<label htmlFor="hinfo_phone" className="label-style-1">Phone</label>
							</div>
							<div className="medium-9 small-12 columns">
								<input type="tel" value={homeAddress.phone} 
									name="phone" id="hinfo_phone"
									minLength="7"
									maxLength="21"
									required="required"
									onChange={e => homeForm_HandleChange(e.target)} />
							</div>
						</div>
					</div>
					<div>
						<button type="button" className="button"
						onClick={e => {cancelEditing()}} 
						>Cancel</button> <button 
							disabled={disableSubmitBtn}
							type="submit" className="button"
						>Save Home Details</button>
					</div>
				</form>
			</div>
				</div>
			</div>
		);
	}


	//Business tab
	const [requireBusinessCounty, setRequireBusinessCounty] = useState( (selectedUser.businessAddress.state.toLowerCase() === 'nj') );
	const [businessName, setBusinessName] = useState( {
		businessName: selectedUser.businessName,
		id: userId
	} );

	const [businessAddress, setBusinessAddress] = useState( {
		...selectedUser.businessAddress,
		zip: FormatZipcode(selectedUser.businessAddress.zip),
		phone: FormatPhoneNumber(selectedUser.businessAddress.phone)
	} );
	
	const businessForm_requireCounty = (e_target) => {
        setBusinessAddress(businessAddress => ({
            ...businessAddress,
            county: ''
		}));

		if (e_target.value.toLowerCase() === 'nj' ) {
			setRequireBusinessCounty(true);
		}else{
			setRequireBusinessCounty(false);
		}
		
	};

	const businessForm_HandleChange = (e_target) => {
		const { name, value } = e_target;

		if( name === 'businessName' ) {
			setBusinessName(businessName => ({
				...businessName,
				[name]: value
			}));
		}else{
			
			let updateValue = value;
			
			setBusinessAddress(businessAddress => ({
				...businessAddress,
				[name]: updateValue
			}));
		}
	}
	
	const [businessAddressAlert, setBusinessAddressAlert] = useState({
		type: 'success',
		display: 'none',
		title: '',
		message: ''
	});
	
	
	const handleBusinessDetailsEvent = (e) => {
		
		e.preventDefault();

		//console.log('Submitting home details: ',  homeAddress);
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return () => {}; 
		}

		SetDisableSubmitBtn(true);
		//Special: Business currently is set in /api/cfa
		//the address is set in /api/contact
		//	> So we're going to use axios.all
		COMBO_business_contact(
			tokenInfo.getAuthToken,
			selectedUser.businessAddressId, 
			{
				...businessAddress,
				zip: (''+businessAddress.zip).replace(/[^0-9]/g, '').substr(0,9),
				phone:(''+ businessAddress.phone).replace(/[^0-9]/g, '').substr(0,11),
			}, 
			businessName
		)
		.then(function(response){
			//console.log('Updating this current user.', response);
			
			setBusinessAddressAlert({
				type: 'success',
				display:'block', 
				title: 'Successfully updated!',
				message: 'We have saved this CFA\'s business address information.'	
			});
			SetDisableSubmitBtn(false);
			document.getElementById('business-details-div').scrollIntoView();
		})
		.catch(function(error){
			console.error('Failed to update this user\'s home details.', error);

			setBusinessAddressAlert({
				type: 'alert',
				display:'block', 
				title: 'Error',
				message: (error.message || error)
			});
			SetDisableSubmitBtn(false);
			//setDisableRegBtn(false);
		});
		return false;
	};
	const generateBusinessForm = () => {
		return (
			<div className="columns medium-10 large-8 large-offset-2 medium-offset-1" style={{display: (profileForm==='business' ? 'block' : 'none')}}>
				<div>
					<div id="business-details-div">
						<h3>Update Business Details</h3>
						<div className={"callout " + businessAddressAlert.type} style={{display:businessAddressAlert.display}} data-closable>
							<h5>{businessAddressAlert.title}</h5>
							<p>{businessAddressAlert.message}</p>
							<button className="close-button" aria-label="Dismiss alert" type="button" onClick={(e)=>{
								window.jQuery( window.jQuery(e.currentTarget).closest('div') ).fadeOut('slow', () => {
									setBusinessAddressAlert({
										type: 'success',
										display: 'none',
										title: '',
										message: ''
									})
								});
							}}
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<form action="" method="post" onSubmit={handleBusinessDetailsEvent}>
							<div className="">
								<div className="row"> 
									<div className="medium-3 small-12 columns">
										<label htmlFor="binfo_street" className="label-style-1">Business Name</label>
									</div>
									<div className="medium-9 small-12 columns">
										<input type="text" value={businessName.businessName} 
											name="businessName" id="binfo_businessName"
											required="required"
											onChange={e => businessForm_HandleChange(e.target)} />
									</div>
								</div>
								<div className="row"> 
									<div className="medium-3 small-12 columns">
										<label htmlFor="binfo_street" className="label-style-1">Address</label>
									</div>
									<div className="medium-9 small-12 columns">
										<input type="text" value={businessAddress.street} 
											name="street" id="binfo_street"
											required="required"
											onChange={e => businessForm_HandleChange(e.target)} />
									</div>
								</div>
								<div className="row"> 
									<div className="medium-3 small-12 columns">
										<label htmlFor="binfo_city" className="label-style-1">City</label>
									</div>
									<div className="medium-9 small-12 columns">
										<input type="text" value={businessAddress.city} 
											name="city" id="binfo_city"
											required="required"
											onChange={e => businessForm_HandleChange(e.target)} />
									</div>
								</div>
								<div className="row"> 
									<div className="medium-3 small-12 columns">
										<label htmlFor="binfo_state" className="label-style-1">State</label>
									</div>
									<div className="medium-9 small-12 columns">
										<ProfactSelect
											name="state" id="binfo_state" StateDropdown={true} 
											required="required"
											value={businessAddress.state}
											onChange={e => {
												businessForm_requireCounty(e.target);
												businessForm_HandleChange(e.target)
											} } />
									</div>
								</div>
								<div className="row"> 
									<div style={{display:(requireBusinessCounty ? 'block' : 'none')}} className="medium-3 small-12 columns">
										<label htmlFor="binfo_county" className="label-style-1">NJ County</label>
									</div>
									<div style={{display:(requireBusinessCounty ? 'block' : 'none')}} className="medium-9 small-12 columns">
										<ProfactSelect
											name="county" id="binfo_county" NjCountyDropdown={true}
											value={businessAddress.county}
											required={requireBusinessCounty}
											onChange={e => businessForm_HandleChange(e.target)} />
									</div>
								</div>
								<div className="row"> 
									<div className="medium-3 small-12 columns">
										<label htmlFor="binfo_zip" className="label-style-1">Zipcode</label>
									</div>
									<div className="medium-9 small-12 columns">
										<input type="text" value={businessAddress.zip} 
															minLength="5"
															maxLength="10"
											name="zip" id="binfo_zip"
											required="required"
											onChange={e => businessForm_HandleChange(e.target)} />
									</div>
								</div>
								<div className="row"> 
									<div className="medium-3 small-12 columns">
										<label htmlFor="binfo_phone" className="label-style-1">Phone</label>
									</div>
									<div className="medium-9 small-12 columns">
										<input type="tel" value={businessAddress.phone} 
											name="phone" id="binfo_phone"
											minLength="7"
											maxLength="21"
											required="required"
											onChange={e => businessForm_HandleChange(e.target)} />
									</div>
								</div>
							</div>
							<div>
								<button type="button" className="button"
								onClick={e => {cancelEditing()}} 
								>Cancel</button> <button 
									disabled={disableSubmitBtn}
									type="submit" className="button"
								>Save Business Details</button>
							</div>
						</form>					
					</div>
				</div>
			</div>
		);
	}
	


	//Pay History
	
	const [paymentHistoryAlert, setPaymentHistoryAddressAlert] = useState({
		type: 'success',
		display: 'none',
		title: '',
		message: ''
	});
	const [payDetails, SetPayDetails] = useState({
		id: null,
		userId: userId,
		payConf: '',
		poNumber: '',
		invoiceNumber: '',
		payMethod: '',
		payPurpose: '',
		payAmount: '',
		payNotes: '',
		cart: [],
		paid: 0 
	});
	
	const INITIAL_RENEWAL = [];
	const myCycleYear = personalInfo.cycleYear;
	const myActiveYear = personalInfo.activeYear;
	////console.log('Renewal check: ', [myCycleYear, myActiveYear]);
	if( myCycleYear && myActiveYear ){
		for( let startOfCycle = myCycleYear-4; startOfCycle<=myCycleYear; startOfCycle++ ) {
			if( startOfCycle <= myActiveYear ) {
				INITIAL_RENEWAL.push( {
					year: startOfCycle,
					isPaid: true,
					isChosen: false,
				});
				/*
				myRenewalButtons.push(<span>
					<i className="fa fa-certificate"></i> {startOfCycle} Paid!
				</span>);*/
			}else{
				INITIAL_RENEWAL.push( {
					year: startOfCycle,
					isPaid: false,
					isChosen: false,
				});
			}
		}
	}
	
	const [renewalOptions, setRenewalOptions] = useState(INITIAL_RENEWAL);

	const [paymentHistoryTable, setPaymentHistoryTable] = useState([]);
	const [phContentLoading, setPhContentLoading] = useState(true);
	const refreshPayHistory = (userId) => {
		
		setPhContentLoading(true);

		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return () => {};
		}
		//three independent 
		////console.log(tokenInfo);

		payment_cfa_history(tokenInfo.getAuthToken,  userId)
		.then( (res) => {
			if( res.data.history && res.data.code === 'CFA_PAYMENT_HISTORY') {
				//create book
				setPhContentLoading(false);
				setPaymentHistoryTable(res.data.history);
			}else{
				throw new Error(res.data);
			}
		} )
		.catch( error => {
			console.error('Getting CFA\'s history failed.', error);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Could not fetch CFA Payment History. ');
			}
			setPhContentLoading(false);
			setPaymentHistoryTable(null);
		});
	}

	const parseCart = (cartInfo) => {
		try{
			return JSON.parse(cartInfo);
		} catch (objError) {
			return {"items":[],"comments":"Error loading cart."};
		}
	}
	const getCartDate = (cartItems) => {
		if( !cartItems ) {
			return 'N/A';
		}
		const myShoppingCart =  parseCart(cartItems); //JSON.parse(cartItems);
		if( myShoppingCart.originalDate ) {
			return myShoppingCart.originalDate;
		}

		return null;
	}

	const getCartItems = (payPurpose, cartItems) => {
		if( !cartItems ) {
			return 'N/A';
		}
		//const myShoppingCart = JSON.parse(cartItems);
		const myShoppingCart =  parseCart(cartItems); //JSON.parse(cartItems);

		const myCart = (
			myShoppingCart.items ?
			myShoppingCart.items
			: myShoppingCart
		);
		
		if( myCart && myCart.orderId ){
			return `Old Order: ${myCart.orderId}`;
		}
		if( myCart && (myCart.name || myCart.phone) ){
			const myInfo = [];
			if( myCart.name ) { myInfo.push( myCart.name) }
			if( myCart.phone ) { myInfo.push( 'Ph: ' + myCart.phone) }
			return `${myInfo.join('; ')}`;
		}

		////console.log(myCart);
		if( payPurpose.toLowerCase() === 'exam' ) {
			if( myCart[0] ) {
				return `CFA Exam ($${myCart[0].amount})`;
			}
			return `CFA Exam Purchase`;
		}else if( payPurpose.toLowerCase() === 'tfa' ) {
			let numTfas = 0;
			let tfaCost= 0.0;
			if( myCart.forEach) {
				myCart.forEach((tfa) => {
					numTfas++;
					tfaCost += tfa.amount;
				});
				//return `TFAs (${numTfas} TFA for $${tfaCost})`;
				return `${numTfas} TFAs for $${tfaCost}`;
			}
			return `TFA Purchase`;
		}else if( payPurpose.toLowerCase() === 'course' ) {
			if( myCart[0] ) {
				return `CE Course "${myCart[0].title}" ($${myCart[0].amount})`;
			}
			return `CE Course Purchase`;
		}else if( payPurpose.toLowerCase() === 'renewal' ) {
			//let numRenews = 0;
			//let renewCost= 0.0;
			if( myCart.forEach) {
				const renewals = [];
				myCart.forEach((renew) => {
					if( !renew.year ) { return; }
					//numRenews++; 
					//renewCost += renew.amount;
					renewals.push( renew.year );
				});
				return `Renewals: ${renewals.join(', ')}`;
			}
			return `Renewal Purchase`;
		}


		return '';
	}

	
	
	const deletePayItem = () => {
		
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return false;
		}

		setDisableDelPayBtn(true);

		admin_delete_pay_form( tokenInfo.getAuthToken, payDetails)
			.then( (res) => {
				//console.log('Removed Pay details', res);
				refreshPayHistory(userId);
				ClearPaymentForm();
				setDisableDelPayBtn(false);

			} )
			.catch( error => {
				console.error('Updating payment [submitPayInfo] Failed.', error);
				if( error.statusCode && error.statusCode === 500) {
					console.error('Could not add/update the payment for this CFA.');
				}
				setDisableDelPayBtn(false);
			});
			
	}
	const submitPayInfo = (e) => {
		e.preventDefault();
		
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return false;
		}
		const myPayDetails = payDetails;

		myPayDetails.paid = ( payDetails.payMethod === 'adminWaived' ||  (payDetails.paid === true ||  payDetails.paid === 1) );

		const myItems = {items:[], comments: payDetails.payNotes};
		if( payDetails.payPurpose === 'exam' ) {
			myItems.items = [
				{"amount":payDetails.payAmount,"code":"Sdvb8dL","quantity":"1","title":"CFA Exam"}
			];
		}else if( payDetails.payPurpose === 'tfa' ) {
			const costPerTFA=40;
			const myTfas = unpaidTfaList.filter((unpaidTfaInfo) => { return unpaidTfaInfo.isChosen; })
				.map( (unpaidTfaInfo)  => {
					myItems.items.push({
						amount: costPerTFA,
						code:"Sdvb8dL",
						quantity:1,
						title: unpaidTfaInfo.lastName + ', ' + unpaidTfaInfo.firstName + ' (' + unpaidTfaInfo.id+ ')',
						tfaId: unpaidTfaInfo.id
					});
					return unpaidTfaInfo.id;
				});
			myPayDetails.myTfas = myTfas;
		}else if( payDetails.payPurpose === 'renewal' ) {
			const costPerRenew = 40.00;
			const myRenewalYears = renewalOptions.filter((renewalOptions) => { return renewalOptions.isPaid===false && renewalOptions.isChosen; })
			.map( (renewalOptions)  => {
				myItems.items.push({
					amount: costPerRenew,
					code:"Sdvb8dL",
					quantity:1,
					title: 'Renewal Year: ' + renewalOptions.year,
					year: renewalOptions.year
				});
				return renewalOptions.year;
			});
			myPayDetails.myRenewalYears = myRenewalYears;
		}else if( payDetails.payPurpose === 'course' ) {
			
			myItems.items = [
				{
					"courseId":selectedCourseId.courseId,
					"creditId":selectedCourseId.creditId,
					"amount":selectedCourseId.course.cost,
				"code":"Sdvb8dL","quantity":"1","title": "CE Course: " + selectedCourseId.course.title}
			];
			myPayDetails.myCourseId = selectedCourseId.courseId;
			myPayDetails.myCreditId = selectedCourseId.creditId;
		}

		
		myPayDetails.cart = myItems;

		SetDisableSubmitBtn(true);

		AUTO_admin_payment( tokenInfo.getAuthToken, myPayDetails)
			.then( (res) => {
				//console.log('Automatic Payment details', res);

				if( payDetails.payPurpose === 'exam' ) {
					setPersonalInfo({
						...personalInfo,
						examPaid: ( payDetails.payMethod === 'adminWaived' ||  (payDetails.paid === true ||  payDetails.paid === 1) )
					});
				}else if( payDetails.payPurpose === 'tfa' ) {
					refreshTfas(userId);
				}else if( payDetails.payPurpose === 'renewal' ) {
					if( myPayDetails.paid ) {
						setPersonalInfo({
							...personalInfo,
							activeYear: personalInfo.activeYear + myPayDetails.myRenewalYears.length
						});
					}
				}else if( payDetails.payPurpose === 'course' ) {
					setSelectedCourseId(null);
					getCourseList(userId);
				}

				SetDisableSubmitBtn(false);
				refreshPayHistory(userId);
				ClearPaymentForm();

			} )
			.catch( error => {
				console.error('Updating payment [submitPayInfo] Failed.', error);
				if( error.statusCode && error.statusCode === 500) {
					console.error('Could not add/update the payment for this CFA.');
				}
				SetDisableSubmitBtn(false);
			});
			
	}
	const payForm_HandleChange = (e_target) => {
		const { name, value } = e_target;
		////console.log(e_target, );
		SetPayDetails(payDetails => ({
			...payDetails,
			[name]: value
		}));
	}
	const payForm_checkBox_HandleChange = (e_target) => {
		const { name, checked } = e_target;
		SetPayDetails(payDetails => ({
			...payDetails,
            [name]: (checked ? 1 : 0)
		}));
	}
	
	
	const selectTfa = (unpaidTfaInfo) => {
		let myTfaCost = 0.00;
		
		const myUnpaidTfas = unpaidTfaList.map( (tfaInfo) => {
			if( tfaInfo.id === unpaidTfaInfo.id ) {
				tfaInfo.isChosen=!tfaInfo.isChosen;
			}

			if( tfaInfo.isChosen ) {
				myTfaCost+=40.00;
			}
			return tfaInfo;
		});
		setUnpaidTfaList(myUnpaidTfas);
		SetPayDetails(payDetails => ({
			...payDetails,
            payAmount: myTfaCost
		}));
	}

	const toggleRenewalYearToCart = (year) => {
		let numberOfRenewals = 0;
		const myRenewalOptions = renewalOptions.map( 
			(renewalItem) => {
				if( renewalItem.isPaid ) {
					return renewalItem;
				}
				if( renewalItem.year === year ) {
					renewalItem.isChosen = !renewalItem.isChosen;
				}else{
					//If we toggle an earlier year, remove later options.
					if( year < renewalItem.year ) {
						renewalItem.isChosen=false;
					}
				}

				if( renewalItem.isChosen ) {
					numberOfRenewals++;
				}

				return renewalItem;
			}
		);
		setRenewalOptions(myRenewalOptions);
		SetPayDetails(payDetails => ({
			...payDetails,
            payAmount: (  40.00 * numberOfRenewals )
		}));

		return true;
	}
	const _PAY_PURPOSE_CONTROLS = () => { 


		if( payDetails.payPurpose === 'exam' ) {
			////console.log('Exam chosen.');
			return (<></>);
		}else if( payDetails.payPurpose === 'tfa' ) {
			return (<>
				<div className="small-12 columns">
					<h4>Select Unpaid TFAs below: </h4>
					<div>
						<ul className="no-ul-bullet">
						{
							(unpaidTfaList.length < 1) ?
							<li> You have no TFAs you need to pay for this year! </li>
							:
							//onClick={(e) =>{ selectTheCeCourse( courseInfo ) }}>
							unpaidTfaList.map( (unpaidTfaInfo, key)  => {
								return (
									<li key={key}> 
										{
											unpaidTfaInfo.isChosen ?
											(
												<button type="button" className={
													"override-sebs button small success vertical-align-middle no-bottom-margin" 
												}
												onClick={(e) =>{ selectTfa( unpaidTfaInfo ) }}>
												<i className="fa fa-user-times"></i> Remove TFA</button> 
											) :  (
												<button type="button" className={
													"button small primary vertical-align-middle no-bottom-margin"
												}
												onClick={(e) =>{ selectTfa( unpaidTfaInfo ) }}>
												<i className="fa fa-user-plus"></i> Add TFA</button> 
											)
										} {unpaidTfaInfo.firstName} {unpaidTfaInfo.lastName}
									</li>
								);
							})
						}
						</ul>
					</div>
				</div>
			</>);
		}else if( payDetails.payPurpose === 'renewal' ) {
			if( renewalOptions.length < 1 ) {
				return (<>
					<div className="small-12 columns">
						<p> <strong>This user has not passed his or her exam yet and does not have a cycle started to approve years.</strong> </p>
					</div>
				</>);
			}
			const myRenewalButtons = [];
			let foundUnpaid = false;
			for(let i=0;i<renewalOptions.length;i++) {
				if( renewalOptions[i].isPaid ) {
					myRenewalButtons.push(<span>
						<i className="fa fa-certificate"></i> {renewalOptions[i].year} Paid!
					</span>);
				}else{
					if( !foundUnpaid ) {
						if( renewalOptions[i].isChosen === false ) {
							foundUnpaid=true;
							myRenewalButtons.push(<button type="button" className="override-sebs button small success vertical-align-middle no-bottom-margin"  
								onClick={(e) =>{ toggleRenewalYearToCart(renewalOptions[i].year) }}
							>
								<i className="fa fa-cart-plus"></i> Add {renewalOptions[i].year}  
							</button>);
						}else{
							myRenewalButtons.push(<button type="button" className="override-sebs button small alert vertical-align-middle no-bottom-margin"  
								onClick={(e) =>{ toggleRenewalYearToCart(renewalOptions[i].year) }}
							>
								<i className="fa fa-minus"></i> Remove {renewalOptions[i].year}  
							</button>);
						}
					}else{
						myRenewalButtons.push(<button type="button" 
							disabled={true}
							className="override-sebs button small success vertical-align-middle no-bottom-margin"  
						>
							<i className="fa fa-cart-plus"></i> Add {renewalOptions[i].year}  
						</button>);
					}

				}
			}
			
			return (<>
				<div className="small-12 columns">
					<ul className="no-ul-bullet">
					{
						//onClick={(e) =>{ selectTheCeCourse( courseInfo ) }}>
						myRenewalButtons.map( (renewalBtn, key)  => {
							return (
								<li key={key}> 
									{renewalBtn}
								</li>
							);
						})
					}
					</ul>
				</div>
			</>);
		}else if( payDetails.payPurpose === 'course' ) {
			////console.log(courseRegistration);
			////console.log(courseList);
			return (<>
				<div className="small-12 columns">
					<p> Select a single course to apply credit to: </p>
					<ul>
					{
						courseList.registered.filter( (unpaidItem) => {
							return unpaidItem.payId === null && unpaidItem.course.cost !== 0;
						}).map(
							(unpaidItem, key) => {
								////console.log('Unpaid Item:', unpaidItem);
								return (
									<li key={key}> 
										{
											( selectedCourseId && unpaidItem.courseId === selectedCourseId.courseId ) ?
											(
												<button type="button" className={
													"override-sebs button small success vertical-align-middle no-bottom-margin" 
												}
												onClick={(e) =>{ 
												}}>
												<i className="fa fa-check"></i> Paying For This Course</button> 
											) :  (
												<button type="button" className={
													"button small primary vertical-align-middle no-bottom-margin"
												}
												onClick={(e) =>{ 
												//	selectTfa( unpaidTfaInfo )
													setSelectedCourseId(unpaidItem);
													SetPayDetails({
														...payDetails,
														payAmount: unpaidItem.course.cost
													}) 
												}}>
												<i className="fa fa-delete"></i> Choose this course </button> 
											)
										}
										

										{unpaidItem.course.cost.toLocaleString('en-US', {
											style: 'currency',
											currency: 'USD',
										})} {unpaidItem.course.title}
									</li>
								);
							}
						)
					}
					</ul>
				</div>
			</>);
		}


		return (<></>);
	}

	const DeletePayForm = () => {
		if( profileForm !== 'delete-pay-form' ) {
			return null;
		}
/*

		userId: userId,
		payConf: '',
		poNumber: '',
		invoiceNumber: '',
		payMethod: '',
		payPurpose: '',
		payAmount: '',
		payNotes: '',
		cart: [],
		paid: 0 
*/
		return (
			<div style={{display: ( profileForm === 'delete-pay-form' ? 'block' : 'none' )}}>
				<h5> Payment Information: </h5>
				
				{
					payDetails.paid && (payDetails.payMethod === 'eCheck' || payDetails.payMethod.toLowerCase() === 'echeck')
					? 
					<div class="callout warning">
						<h5>Notice</h5>
						<p>
							If you remove / revoke this payment, please remember to revert the changes made by this transaction manually for this user.
							You may want to print this page for your records.
						</p>
					</div>
					:null
				}
				
				<div>
					<dl>
						<dt>Confirmation Number:</dt>
							<dd>{payDetails.payConf}</dd>
						<dt>Pay Method:</dt>
							<dd>{payDetails.payMethod}</dd>
						<dt>Pay Purpose:</dt>
							<dd>{payDetails.payPurpose}</dd>
						<dt>PO Number if available:</dt>
							<dd>{payDetails.poNumber}</dd>
						<dt>Invoice Number if available:</dt>
							<dd>{payDetails.invoiceNumber}</dd>
						<dt>Amount:</dt>
							<dd>{payDetails.payAmount.toLocaleString('en-US', {
						style: 'currency',
						currency: 'USD',
						})}</dd>
						<dt>Item Summary:</dt>
							<dd>{getCartItems(payDetails.payPurpose,payDetails.cart)}</dd>
						<dt>Comments</dt>
							<dd>
								<p className="no-bottom-margin">{payDetails.payNotes}</p>
							</dd>
					</dl>
				</div>
				<h5> <strong>NOTICE: </strong> This action cannot be undone.</h5>
				<div>
					<button onClick={ (e) => { 
							ClearPaymentForm();
						} } 
						className="override-sebs button primary success" type="button">
						Cancel (DO NOT DELETE) </button>  <button onClick={ (e) => { 
							//UpdateTfaFormData(STOCK_TFA); toggleShowContent('tfa-list'); 
							deletePayItem();
						} } 
						className="button primary alert" disabled={disableDelPayBtn} type="button">
						<i className="fas fa-trash"></i> REMOVE PENDING PAYMENT FROM CFA </button>
				</div>
			</div>
		);
	}

	const PayForm = () => {

		return (
			<div className="columns medium-10 large-8 large-offset-2 medium-offset-1" style={{display: (profileForm==='pay-form' ? 'block' : 'none')}}>
				<div>
					<div>
						{
							payDetails.id ? 
							<h3>Updating this CFA's payment details</h3>
							: <h3> Add a new payment to this CFA</h3>
						}
						<p><em>If using the "PO / Invoice" payment method, at least a PO number or Invoice Number needs to be filled out.</em></p>
						<div className={"callout " + paymentHistoryAlert.type} style={{display:paymentHistoryAlert.display}} data-closable>
							<h5>{paymentHistoryAlert.title}</h5>
							<p>{paymentHistoryAlert.message}</p>
							<button className="close-button" aria-label="Dismiss alert" type="button" onClick={(e)=>{
								window.jQuery( window.jQuery(e.currentTarget).closest('div') ).fadeOut('slow', () => {
									setPaymentHistoryAddressAlert({
										type: 'success',
										display: 'none',
										title: '',
										message: ''
									})
								});
							}}
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<form action="" method="post" onSubmit={submitPayInfo}>
							<div className="">
								<div className="row">
									<div className="medium-3 small-12 columns">
										<label htmlFor="payPurpose" className="label-style-1">Payment Purpose</label>
									</div>
									<div className="medium-9 small-12 columns">
										<select id="payPurpose" name="payPurpose" value={payDetails.payPurpose}
											required="required"
											onChange={e => {
												if( !payDetails.id ) {
													SetPayDetails( {
														...payDetails,
														payConf: getConfNumber(e.target.value,9)
													});
												}
												payForm_HandleChange(e.target)
											}} 
										>
											<option value="">Select Payment Purpose</option>
											<option value="exam">Exam</option>
											<option value="tfa">TFAs</option>
											<option value="renewal">Renewal</option>
											<option value="course">Course</option>
										</select>
									</div>
								</div>
								<div className="row">
									<div className="medium-3 small-12 columns">
										<label htmlFor="payConf" className="label-style-1">Confirmation Number</label>
									</div>
									<div className="medium-9 small-12 columns">
										<input type="text" value={payDetails.payConf} 
											name="payConf" id="payConf"
											required="required"
											onChange={e => payForm_HandleChange(e.target)} />
									</div>
								</div>
								<div className="row">
									<div className="medium-3 small-12 columns">
										<label htmlFor="payMethod" className="label-style-1">Payment Method</label>
									</div>
									<div className="medium-9 small-12 columns">
										<select id="payMethod" name="payMethod" value={payDetails.payMethod}
											required="required"
											onChange={e => {
												payForm_HandleChange(e.target)} 

										}>
											<option value="">Select Payment Method</option>
											<option value="po">PO </option>
											<option value="invoice">Invoice</option>
											<option value="check">Check</option>
											<option value="adminWaived">WAIVE PAYMENT</option>
											<option value="online">Online* </option>
										</select>
										<p style={{display: (payDetails.payMethod === 'online' ? 'block' : 'none' )}}><strong>*</strong> While this option ("Online") is available for you to select, it is mainly used for CFA electronic payments. Please try to use another option if available.</p>
									</div>
								</div>
								<div className="row">
									<div className="medium-3 small-12 columns">
										<label htmlFor="poNumber" className="label-style-1">PO Number</label>
									</div>
									<div className="medium-9 small-12 columns">
										<input type="text" value={payDetails.poNumber} 
											name="poNumber" id="poNumber"
											onChange={e => payForm_HandleChange(e.target)} />
									</div>
								</div>
								<div className="row">
									<div className="medium-3 small-12 columns">
										<label htmlFor="invoiceNumber" className="label-style-1">Invoice Number</label>
									</div>
									<div className="medium-9 small-12 columns">
										<input type="text" value={payDetails.invoiceNumber} 
											name="invoiceNumber" id="invoiceNumber"
											onChange={e => payForm_HandleChange(e.target)} />
									</div>
								</div>
								<div className="row">
								{_PAY_PURPOSE_CONTROLS()}
								</div>
								<div className="row">
									<div className="medium-3 small-12 columns">
										<label htmlFor="payAmount" className="label-style-1">Amount Paid</label>
									</div>
									<div className="medium-9 small-12 columns">
										<input type="text" value={payDetails.payAmount} 
											name="payAmount" id="payAmount"
											required="required"
											onChange={e => payForm_HandleChange(e.target)} />
									</div>
								</div>
								<div className="row">
									<div className="small-12 columns">
										<label htmlFor="paid" className="label-style-1">
											<input type="checkbox" 
												name="paid" id="paid" 
												checked={payDetails.paid}
												onChange={(e)=>{ payForm_checkBox_HandleChange(e.target); }}
											/>
											Approve Payment
										</label>
									</div>

									<div className="medium-12 small-12 columns">
										<label htmlFor="payNotes" className="label-style-1">Personal Notes</label>
										<p><em>Are there any notes you would like to attach to this payment?</em></p>
									</div>
									<div className="medium-12 small-12 columns">
										<textarea value={payDetails.payNotes}
											name="payNotes" id="payNotes"
											onChange={e => payForm_HandleChange(e.target)} ></textarea>
									</div>
								</div>
							</div>
							{
								payDetails.payMethod === 'adminWaived'
								? <div><p><strong><em>NOTE: WAIVING AN PAYMENT WILL AUTOMATICALLY APPROVE THIS PAYMENT. THIS ACTION CANNOT BE UNDONE.</em></strong></p></div>
								: null
							}
							<div>
								<button type="button" className="button"
								onClick={e => {ClearPaymentForm()}} 
								>Cancel Payment</button> <button 
									disabled={disableSubmitBtn}
									type="submit" className="button"
								>Save Payment Details</button>
							</div>
						</form>					
					</div>
				</div>
			</div>
		);



	}
	const getConfNumber = (type, length = 9) => {
		if( !type ) {
			return '';
		}

		let prepend='__';

		if( type==='exam' ) {
			prepend='CE';
		}else if( type==='tfa' ) {
			prepend='TF';
		}else if( type==='renewal' ) {
			prepend='RN';
		}else if( type==='course' ) {
			prepend='CC';
		}

		return prepend + Math.random().toString().substr(2, length);
	}
	
	const ClearPaymentForm = () => {
		SetPayDetails({
			id: null,
			userId: userId,
			payConf: '',
			poNumber: '',
			invoiceNumber: '',
			payMethod: '',
			payPurpose: '',
			payAmount: '',
			payNotes: '',
			paid: false 
		});


		//Clear tfas
		const myUnpaidTfas = unpaidTfaList.map( (tfaInfo) => {
			tfaInfo.isChosen=false;
			return tfaInfo;
		});
		setUnpaidTfaList(myUnpaidTfas);
		//Clear renewals
		setRenewalOptions( INITIAL_RENEWAL );
		//clear selected course
		setSelectedCourseId(null);

		//Show list
		setProfileForm( 'update-payments' );
	}
	const GetPaymentInformation = (payId, forApproval = true) => {
		//Find the pay id in the history
		const findPayment = paymentHistoryTable.find( ( payInfo ) => { return payInfo.id === payId; } );

		////console.log(findPayment);
		const myCart = parseCart(findPayment.cart); //JSON.parse(findPayment.cart || `{"items":[],"comments":"N/A"}`);
		//const myCart = JSON.parse(findPayment.cart || `{"items":[],"comments":"N/A"}`);

		SetPayDetails( {
			...findPayment,
			payNotes: (myCart && myCart.comments ? myCart.comments : ''),
			invoiceNumber: findPayment.invoiceNumber || '',
			poNumber: findPayment.poNumber || ''
		});

		if( forApproval ) {
			//If tfas, we need to reselect
			/*

			*/
			if( findPayment.payPurpose === 'tfa' ) {
				const myTfaIds = myCart.items.map( (tfaItem) => { return tfaItem.tfaId; } );
				////console.log('Found TFA Ids:', myTfaIds);
				
				setUnpaidTfaList( 
					unpaidTfaList
					.map( (tfaItem) => { 
						//console.log('Looking for ', tfaItem.id, ' in ', myTfaIds);
						tfaItem.isChosen = myTfaIds.includes( tfaItem.id ); 
						return tfaItem ;
					}) 
				);
			}else if( findPayment.payPurpose === 'renewal' ) {
				////console.log('Let us find renewal years: ', findPayment, myCart);
				const myRenewalYears = myCart.items.map( (renewItem) => { return renewItem.year; } );
				////console.log('My renewal years', myRenewalYears)
				setRenewalOptions(
					renewalOptions.map( (renewalItem) => {
						////console.log('Looking for ', renewalItem.year, ' in ', myRenewalYears, 'with', renewalItem);
						renewalItem.isChosen=myRenewalYears.includes( renewalItem.year ); 

						return renewalItem;
					})
				)
			}else if( findPayment.payPurpose === 'course' ) {
				////console.log('Let us find the selected course id...: ', courseList, myCart);
				setSelectedCourseId(courseList.registered.find( (unpaidItem) => {
					return unpaidItem.creditId === myCart.items[0].creditId;
				}));
	

			}


			setProfileForm( 'pay-form' ); 
		}else{
			setProfileForm( 'delete-pay-form' ); 
		}
/*


	const [payDetails, SetPayDetails] = useState({
		id: null,
		userId: userId,
		payConf: '',
		poNumber: '',
		invoiceNumber: '',
		payMethod: '',
		payPurpose: '',
		payAmount: '',
		payNotes: '',
		cart: [],
		paid: 0 
	});
	paymentHistoryTable
*/


		return <></>;
	}
	const OutputPaymentHistory = () => {
		if( phContentLoading ) {
			return (
				<tr><td colSpan="13"><i className="fas fa-circle-notch fa-spin"></i></td></tr>
			);
		}
		if( !paymentHistoryTable ) {
			return (
				<tr><td colSpan="13">Could not retrieve your payment history.</td></tr>
			);
		}

		if( paymentHistoryTable.length < 1 ) {
			return (
				<tr><td colSpan="13">No Payment History yet.</td></tr>
			);
		}
		return paymentHistoryTable.map( (paymentObj, key) => {
			////console.log(paymentObj);
			return (
				<tr key={key}>
					<td>{paymentObj.id}</td>
					<td>{paymentObj.payPurpose}</td>
					<td>{paymentObj.payConf}</td>
					<td>{paymentObj.invoiceNumber}</td>
					<td>{paymentObj.poNumber}</td>
					<td>{paymentObj.payAmount.toLocaleString('en-US', {
						style: 'currency',
						currency: 'USD',
						})}</td>
					{paymentObj.paid ? <td>Yes</td> : (<td style={{backgroundColor:'lightgray'}}><strong>No</strong></td>) }
					<td>{
							getCartDate(paymentObj.cart ) ||
							moment.tz(paymentObj.updatedAt,'YYYY-MM-DDTHH:mm:ss.SSSZ', 'Etc/UTC')
							.tz( moment.tz.guess() )
							.format('L LT')
					}</td>
					<td>{paymentObj.cart ? getCartItems(paymentObj.payPurpose, paymentObj.cart) : 'N/A'}</td>
					{
						paymentObj.paid ?
						<td></td>
						: 
							<td>
								<button className="button warning small no-bottom-margin override-sebs"
									onClick={ (e) => { GetPaymentInformation(paymentObj.id, true) } }
								><i className="fa fa-pencil-alt"></i></button>
							</td>
					}
					
					{
						/* Issue #5; If this is specifically a method "eCheck", then we display the delete button always? */	
						paymentObj.paid && paymentObj.payMethod !== 'eCheck' ?
						<td></td>
						:
							<td>
								<button className="button alert small no-bottom-margin"
									onClick={ (e) => { GetPaymentInformation(paymentObj.id, false) } }
								><i className="fa fa-trash"></i> </button>
							</td>
						
					}
				</tr>
			);
		});
	}
	const generatePayHistory = () => {
		return (<>
			<div className="columns small-12" style={{display: (profileForm==='update-payments' ? 'block' : 'none')}}>
				<div>
			<div >
				<h3>Payment History</h3>
				<div className={"callout " + paymentHistoryAlert.type} style={{display:paymentHistoryAlert.display}} data-closable>
					<h5>{paymentHistoryAlert.title}</h5>
					<p>{paymentHistoryAlert.message}</p>
					<button className="close-button" aria-label="Dismiss alert" type="button" onClick={(e)=>{
						window.jQuery( window.jQuery(e.currentTarget).closest('div') ).fadeOut('slow', () => {
							setPaymentHistoryAddressAlert({
								type: 'success',
								display: 'none',
								title: '',
								message: ''
							})
						});
					}}
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>

				<div>
					<button className="button small primary" onClick={(e) =>{ 
						ClearPaymentForm();
						setProfileForm( 'pay-form' ) 
					}}>Add New Payment Item</button>
				</div>
				<div>
					<p> To approve a payment, please enter into the approve form and make final adjustments. Once a payment is approved, it cannot be modified or deleted.</p>
				</div>
				<div className="table-scroll">
					<table className="table-left-align-all">
						<thead>
							<tr>
								<th>Id</th>
								<th>Purpose</th>
								<th>Conf. No. </th>
								<th>Invoice</th>
								<th>PO Number</th>
								<th>Cost</th>
								<th>Paid / Approved</th>
								<th>Date</th>
								<th>Details</th>
								<th>Update</th>
								<th>Delete</th>
							</tr>
						</thead>
						<tbody>
							{OutputPaymentHistory()}
						</tbody>
					</table>
				</div>

			</div>
				</div>
			</div>
		</>)
	}

	//TFAs
	
	const [tfaAlert, setTfaAlert] = useState({
		type: 'success',
		display: 'none',
		title: '',
		message: ''
	});
	const [sortedTfaTable, SetTfaSortedVars] = useState({
		var: 'lastName',
		dir: 'asc'
	});
	
	const [tfaList, setTfaList] = useState([]);
	const [unpaidTfaList, setUnpaidTfaList] = useState([]);

	const [tfaContentLoading, setTfaContentLoading] = useState(true);
	const refreshTfas = (userId) => {
		
		setTfaContentLoading(true);

		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return () => {};
		}
		//three independent 
		////console.log(tokenInfo);

		get_tfas_by_cfas( tokenInfo.getAuthToken, userId ) 
		.then( (res) => {
			const currentYear = new Date().getFullYear() ;
			//console.log('TFAs by CFA: ', res.data);
			if( res.data.data && res.data.code === 'TFA_BY_CFA') {
				//Let's pull out the cfa exam vs. ce courses
				setTfaContentLoading(false);
				setTfaList( 
					res.data.data
				);
				setUnpaidTfaList( 
					res.data.data
					//.filter( (tfaItem) => { return !tfaItem.payId; } )
					.filter( (tfaItem) => { 
						return !tfaItem.payId || tfaItem.activeYear < currentYear; 
					} )
					.map( (tfaItem) => { tfaItem.isChosen = false; return tfaItem }) 
				);
			}else{
				throw new Error(res.data);
			}
		} )
		.catch( error => {
			console.error('Getting [TFA_BY_CFA] Failed.', error);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Could not fetch TFAs for this CFA.');
			}
			setTfaContentLoading(false);
			setTfaList( null );
		});
	}
	
	const STOCK_TFA = {

		tfaId: null,

		firstName: '',
		lastName: '',
		email:'',
		homeAddressId: '',
		cfaId: userId,
		spanishPref: 0,
		payId: null,
		activeYear: null, //new Date().getFullYear(),

		//Address 
		homeStreet: '',
		homeCity: '',
		homeState: 'NJ',
		homeZip: '',
		homePhone: '',
		homeCounty: '',

		confirmTfa: true,
	};

	////console.log('STOCK TFA', props.UserState, STOCK_TFA);
	const [tfaFormData, UpdateTfaFormData] = useState(STOCK_TFA);
	const [requireTfaHomeCounty, setRequireTfaHomeCounty] = useState( (tfaFormData.homeState.toLowerCase() === 'nj') );
	const [disableAddTfaBtn, setDisableTfaBtn] = useState(false);
	const [disableDelPayBtn, setDisableDelPayBtn] = useState(false);
	const [deleteTfaFormData, UpdateDeleteTfaFormData] = useState(STOCK_TFA);

	const updateTfaSortable = (newVar, newDir) => {

		SetTfaSortedVars({
			var: newVar,
			dir: newDir
		})
		return true;
	}
	
	const editTfa = (contactId, tfaId) => {
		

		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return () => {};
		}
		//three independent 
		////console.log(tokenInfo);
		const foundTfaInfo = tfaList.find( (tfaInfo) => { return tfaInfo.id === tfaId; } );
		if( !foundTfaInfo ) {
			return null;
		}

		////console.log(foundTfaInfo);
		const myTfaInfo = {
			...STOCK_TFA,
			tfaId: tfaId,
			firstName: foundTfaInfo.firstName,
			lastName: foundTfaInfo.lastName,
			email: foundTfaInfo.email,
			homeAddressId: foundTfaInfo.homeAddressId,
			spanishPref: (foundTfaInfo.spanishPref === true || Number(foundTfaInfo.spanishPref) === 1 ? 1 : 0 ),
			payId: foundTfaInfo.payId,
			activeYear: foundTfaInfo.activeYear,
			
			homeStreet: '',
			homeCity: '',
			homeState: '',
			homeZip: '',
			homePhone: '',
			homeCounty: ''
		};

		if( !contactId ) {
			setRequireTfaHomeCounty( false )
			UpdateTfaFormData(myTfaInfo);
			setProfileForm('tfa-form');
		}else{
			//get_tfa_info( tokenInfo.getAuthToken, tfaId ) 
			get_contact_info( tokenInfo.getAuthToken, contactId )
			.then( (res) => {
				////console.log('Editing a TFA: ', res.data);
				if( res.data.data 
					//&& res.data.code === 'ONE_TFA'
					&& res.data.code === 'ONE_CONTACT'
				) {
					//find tfa in current list
					////console.log('Finding TFAID: `',tfaId,'` in: ', tfaList)
					
					myTfaInfo.homeStreet= res.data.data.street;
					myTfaInfo.homeCity= res.data.data.city;
					myTfaInfo.homeState= res.data.data.state;
					myTfaInfo.homeZip= FormatZipcode( res.data.data.zip);
					myTfaInfo.homePhone= FormatPhoneNumber(res.data.data.phone);
					myTfaInfo.homeCounty= res.data.data.county;

					//Let's pull out the cfa exam vs. ce courses
					setRequireTfaHomeCounty( myTfaInfo.homeState.toLowerCase() === 'nj' );
					UpdateTfaFormData(myTfaInfo);
					setProfileForm('tfa-form');
				}else{
					throw new Error(res.data);
				}
			} )
			.catch( error => {
				console.error('Getting [get_contact] Failed.', error);
				if( error.statusCode && error.statusCode === 500) {
					console.error('Could not fetch TFAs for this CFA.');
				}
			});
		}
	}
	
	const deleteTfa = (contactId, tfaId) => {
		

		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return () => {};
		}
		//three independent 
		////console.log(tokenInfo);

		const foundTfaInfo = tfaList.find( (tfaInfo) => { return tfaInfo.id === tfaId; } );
		if( !foundTfaInfo ) {
			return null;
		}

		////console.log(foundTfaInfo);
		const myTfaInfo = {
			...STOCK_TFA,
			tfaId: tfaId,
			firstName: foundTfaInfo.firstName,
			lastName: foundTfaInfo.lastName,
			email: foundTfaInfo.email,
			homeAddressId: foundTfaInfo.homeAddressId,
			spanishPref: (foundTfaInfo.spanishPref === true || Number(foundTfaInfo.spanishPref) === 1 ? 1 : 0 ),
			payId: foundTfaInfo.payId,
			activeYear: foundTfaInfo.activeYear,
			
			homeStreet: '',
			homeCity: '',
			homeState: '',
			homeZip: '',
			homePhone: '',
			homeCounty: ''
		};

		//get_tfa_info( tokenInfo.getAuthToken, tfaId ) 
		if( !contactId ) {
			UpdateDeleteTfaFormData(myTfaInfo);
			setProfileForm('delete-tfa-form');
		}else{
			get_contact_info( tokenInfo.getAuthToken, contactId )
			.then( (res) => {
				////console.log('Editing a TFA: ', res.data);
				if( res.data.data 
					//&& res.data.code === 'ONE_TFA'
					&& res.data.code === 'ONE_CONTACT'
				) {
					////console.log('Finding TFAID: `',tfaId,'` in: ', tfaList)
					//Deleting Tfa
					myTfaInfo.homeStreet= res.data.data.street;
					myTfaInfo.homeCity= res.data.data.city;
					myTfaInfo.homeState= res.data.data.state;
					myTfaInfo.homeZip= FormatZipcode( res.data.data.zip);
					myTfaInfo.homePhone= FormatPhoneNumber(res.data.data.phone);
					myTfaInfo.homeCounty= res.data.data.county;

					UpdateDeleteTfaFormData(myTfaInfo);
					setProfileForm('delete-tfa-form');
	
					//console.log('Ready to delete: ', myTfaInfo);
				}else{
					throw new Error(res.data);
				}
			} )
			.catch( error => {
				console.error('Getting [get_contact] Failed.', error);
				if( error.statusCode && error.statusCode === 500) {
					console.error('Could not fetch TFAs for this CFA.');
				}
			});
		}
	}
	const getCurrentTfas = () => {
		
		if( tfaContentLoading ) {
			return ( 
				<>
					<tr><td colSpan="10"><i className="fas fa-circle-notch fa-spin"></i></td></tr>
				</>
			);
		}

		return (
			tfaList
			.sort( ( a, b ) =>{

				if( sortedTfaTable.var === 'payId' ) {
					const aVar = a[ sortedTfaTable.var ] || 0;
					const bVar = b[ sortedTfaTable.var ] || 0;

					if( sortedTfaTable.dir === 'asc' ) {
						return aVar - bVar;
					}else  if( sortedTfaTable.dir === 'desc' ) {
						return (aVar - bVar) * -1;
					}
				}

				const aVar = a[ sortedTfaTable.var ].toLocaleLowerCase();
				const bVar = b[ sortedTfaTable.var ].toLocaleLowerCase();

				if( sortedTfaTable.dir === 'asc' ) {
					return aVar.localeCompare(bVar);
				}else  if( sortedTfaTable.dir === 'desc' ) {
					return (aVar.localeCompare(bVar) * -1);
				}

				return 0;
			}).map( (tfaInfo, key)  => {
				////console.log(tfaInfo);
				return (
					<tr key={key}>
						
						<td>{tfaInfo.lastName}</td>
						<td>{tfaInfo.firstName}</td>
						<td>{tfaInfo.email ? tfaInfo.email : 'N/A'}</td>
						{tfaInfo.payId ? <td>Yes</td>: ( <td style={{backgroundColor:'lightgray'}}><strong>No</strong></td>)}
						{tfaInfo.activeYear >= currentYear ? <td>{tfaInfo.activeYear}</td>: ( <td style={{backgroundColor:'lightgray'}}><strong>Expired</strong></td>)}
						<td>{tfaInfo.payId ? tfaInfo.payId: ( <strong>--</strong>)}</td>
						<td>
							<button className="button warning small no-bottom-margin"
								onClick={ (e) => { editTfa(tfaInfo.homeAddressId, tfaInfo.tfaId || tfaInfo.id) } }
							><i className="fa fa-edit"></i> Edit</button>
						</td>
						<td>
							<button className="button alert small no-bottom-margin"
								onClick={ (e) => { deleteTfa(tfaInfo.homeAddressId, tfaInfo.tfaId || tfaInfo.id); } }
							><i className="fa fa-trash"></i> Remove </button>
						</td>
					</tr>
				);
			})
		);
	}
	
	const DeleteTfaForm = () => {
			
		return (
			<div style={{display: ( profileForm === 'delete-tfa-form' ? 'block' : 'none' )}}>
				<h5> TFA Information: </h5>
				<div>
					<dl>
						<dt>Name</dt>
							<dd>{deleteTfaFormData.firstName} {deleteTfaFormData.lastName}</dd>
						<dt>Email</dt>
							<dd>{deleteTfaFormData.email}</dd>
						{
							deleteTfaFormData.homeAddressId 
							? <>
							<dt>Phone Number</dt>
								<dd>{deleteTfaFormData.homePhone}</dd>
							<dt>Address</dt>
								<dd>
									<p className="no-bottom-margin">{deleteTfaFormData.homeStreet}</p>
									<p className="no-bottom-margin">{deleteTfaFormData.homeCity}, {deleteTfaFormData.homeState} {deleteTfaFormData.homeZip} </p>
								</dd>
							</>
							: <></>
							
						}
					</dl>
				</div>
				<h5> <strong>NOTICE: </strong> This action cannot be undone.</h5>
				<div>
					<button onClick={ (e) => { UpdateTfaFormData(STOCK_TFA); setProfileForm('tfa'); } } 
						className="override-sebs button primary success" type="button">
						Cancel (DO NOT DELETE) </button>  <button onClick={ (e) => { 
							
							deleteTfaInfo();
						} } 
						className="button primary alert" type="button">
						REMOVE TFA FROM THE CFA'S LIST </button>
				</div>
			</div>
		);
	}
	
	const submitTfaInfo = (e) => {
		e.preventDefault();
		/*
		if( !tfaFormData.confirmTfa ) {
			console.error('Please confirm the checkbox.');
			return false;
		}
		*/
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return false;
		}

		setDisableTfaBtn(true);

		AUTO_tfa_to_cfa( tokenInfo.getAuthToken, {
			...tfaFormData,
			
			homeZip: (''+tfaFormData.homeZip).replace(/[^0-9]/g, '').substr(0,9),
			homePhone: (''+tfaFormData.homePhone).replace(/[^0-9]/g, '').substr(0,11),
		})
			.then( (res) => {
				//console.log('ADD_NEW_TFA: ', res.data);
				refreshTfas(userId);
				setProfileForm('tfa');
				UpdateTfaFormData( STOCK_TFA );
				setRequireHomeCounty( (STOCK_TFA.homeState.toLowerCase() === 'nj') )
				setDisableTfaBtn(false);
			} )
			.catch( error => {
				console.error('Getting [ADD_NEW_TFA] Failed.', error);
				if( error.statusCode && error.statusCode === 500) {
					console.error('Could not add TFA for this CFA.');
				}
				setDisableTfaBtn(false);
			});
	}
	const tfaForm_HandleChange = (e_target) => {
		const { name, value } = e_target;
		////console.log(e_target, );
        UpdateTfaFormData(tfaFormData => ({
            ...tfaFormData,
            [name]: value
		}));
	}
	const tfaForm_checkBox_HandleChange = (e_target) => {
		const { name, checked } = e_target;
        UpdateTfaFormData(tfaFormData => ({
            ...tfaFormData,
            [name]: (checked ? 1 : 0)
		}));
		//setSpanishText( checked );
	}

	const handleTfaHomeState = (e_target) => {
		
		tfaForm_HandleChange(e_target);
        UpdateTfaFormData(tfaFormData => ({
            ...tfaFormData,
            homeCounty: ''
		}));
		setRequireTfaHomeCounty( e_target.value.toLowerCase() === 'nj' );
	};
	const TfaForm = () => {
		return (
			<div style={{display: ( profileForm === 'tfa-form' ? 'block' : 'none' )}}>
				{
					(tfaFormData.tfaId ? <h3> Editing TFA: {tfaFormData.firstName} {tfaFormData.lastName} </h3>
					: <h3> Adding a new TFA </h3>
					)
				}
				
				<div className="margin-top-3" style={{marginTop: 1 + 'em'}}>
					<form action="" method="post" onSubmit={submitTfaInfo}>
						<div className="row">
							<div className="large-3 medium-12 small-12 columns">
								<label htmlFor="firstName" className="label-style-1">Name: </label>
							</div>
							<div className="large-4 medium-6 small-12  columns">
								<input type="text" id="firstName" name="firstName" placeholder="First Name" required="required" 
									value={tfaFormData.firstName}
									onChange={e => tfaForm_HandleChange(e.target)}
								/>
							</div>
							<div className="large-5 medium-6 small-12  columns">
								<input type="text" id="lastName" name="lastName" placeholder="Last Name" required="required" 
									value={tfaFormData.lastName}
									onChange={e => tfaForm_HandleChange(e.target)}
								/>
							</div>
						</div>
						<div className="row">
							<div className="small-12 cell">
								<label htmlFor="spanishPref" className="label-style-1">
									<input type="checkbox" 
										name="spanishPref" id="spanishPref" 
										defaultChecked={tfaFormData.spanishPref}
										onChange={(e)=>{ tfaForm_checkBox_HandleChange(e.target); }}
									/>
									This TFA prefers Spanish when available
								</label>
							</div>
						</div>
						<div className="row">
							<div className="large-3 medium-12 small-12 columns">
								<label htmlFor="email" className="label-style-1">Email</label>
							</div>
							
							<div className="large-9 medium-12 small-12 columns">
								<input type="email" id="email" name="email" placeholder="name@example.com" 
									value={tfaFormData.email}
									onChange={e => tfaForm_HandleChange(e.target)}
								/>
							</div>
						</div>
						<div className="row">
							<div className="large-3 medium-12 small-12 columns">
								<label htmlFor="homePhone" className="label-style-1">Home Phone Number</label>
							</div>
							<div className="large-9 medium-12 small-12 columns">
								<input type="text" id="homePhone" name="homePhone" placeholder="" 
								 
								minLength="7"
								maxLength="21"
									value={tfaFormData.homePhone}
									onChange={e => tfaForm_HandleChange(e.target)}
								/>
							</div>
						</div>

						<div className="row">
							<div className="large-3 medium-12 small-12 columns">
								<label htmlFor="homeStreet" className="label-style-1">Home Address</label>
							</div>
							<div className="large-9 medium-12 small-12 columns">
								<input type="text" id="homeStreet" name="homeStreet" placeholder=""
								
									value={tfaFormData.homeStreet}
									onChange={e => tfaForm_HandleChange(e.target)}
								/>
							</div>
						</div>
						<div className="row">
							<div className="large-3 medium-12 small-12 columns">
								<label htmlFor="homeCity" className="label-style-1">Home City</label>
							</div>
							<div className="large-9 medium-12 small-12 columns">
								<input type="text" id="homeCity" name="homeCity" placeholder="" 
								
									value={tfaFormData.homeCity}
									onChange={e => tfaForm_HandleChange(e.target)}
								/>
							</div>
						</div>
						<div className="row">
							<div className="large-3 medium-12 small-12 columns">
								<label htmlFor="homeState" className="label-style-1">Home State</label>
							</div>
							<div className="large-9 medium-12 small-12 columns">
								<ProfactSelect
									name="homeState" id="homeState" StateDropdown={true} 
									IncludeBlank={true} 
									value={tfaFormData.homeState}
									onChange={e => {
										handleTfaHomeState(e.target);
										//homeForm_requireCounty(e.target);
										//homeForm_HandleChange(e.target);
									} } />
							</div>
						</div>
						<div className="row" style={{display: (requireTfaHomeCounty ? 'block': 'none')}}>
							<div className="large-3 medium-12 small-12 columns">
								<label htmlFor="homeCounty" className="label-style-1">Home NJ County</label>
							</div>
							<div className="large-9 medium-12 small-12 columns">
								<ProfactSelect
									name="homeCounty" id="homeCounty" NjCountyDropdown={true}
									required={requireTfaHomeCounty}
									value={tfaFormData.homeCounty}
									onChange={e => tfaForm_HandleChange(e.target)}
								/>
							</div>
						</div>
						<div className="row">
							<div className="large-3 medium-12 small-12 columns">
								<label htmlFor="homeZip" className="label-style-1">Home Zipcode</label>
							</div>
							<div className="large-9 medium-12 small-12 columns">
								<input type="text" id="homeZip" name="homeZip" placeholder="" 
									minLength="5"
									maxLength="10"
									value={tfaFormData.homeZip}
									onChange={e => tfaForm_HandleChange(e.target)}
								/>
							</div>
						</div>
						<div className="row">
							<div className="large-12 medium-12 small-12 cell text-right">
								<button onClick={ (e) => { UpdateTfaFormData(STOCK_TFA); setProfileForm('tfa'); } } 
									className="button alert" type="button">
									Cancel</button>
								<button className="button" type="submit" disabled={disableAddTfaBtn}>
									Submit This TFA</button>
							</div>
						</div>
					</form>
				</div>
			</div>


		);
		/*
						<div className="row">
							<div className="callout alert large-12 medium-12 small-12 columns">
								<p>By clicking the checkbox below, I verify that I am a Certified Fertilizer Applicator (CFA) in the state of New Jersey, and that the Trained Fertilizer Applicator (TFA) listed above has received training using the TFA training materials found on the ProFACT web site (profact.rutgers.edu) or an approved alternate, on the application, sale, and use of fertilizer. I also attest that the Trained Fertilizer Applicator understands:</p>
								<ul className="large-margin-left">
									<li>how to use fertilizer application equipment</li>
									<li>the best management practices for applying fertilizer to turf</li>
									<li>the environmental risks associated with improper application of fertilizer</li>
									<li>procedures for preventing as well as responding to spills of fertilizers</li>
									<li>all the laws, rules, and regulations pertaining to the application of fertilizer to turf</li>
								</ul>
								<p>I agree that the Trained Fertilizer Applicator will be in direct supervision by me or a CFA from at all times during which the TFA is applying fertilizer in the state of New Jersey.</p>
							
								<div className="large-4 medium-12 small-12 columns">
									<label htmlFor="confirmTfa" className="label-style-1">I verify, attest, and agree to the statements above.   *</label>
								</div>
								<div className="large-8 medium-12 small-12  columns">
									<input type="checkbox" id="confirmTfa" name="confirmTfa" placeholder="" required="required" 
										value={tfaFormData.confirmTfa}
										checked={tfaFormData.confirmTfa}
										onChange={e => tfaForm_checkBox_HandleChange(e.target)}
									/>
								</div>
							</div>
						</div>
					*/
	}
	const deleteTfaInfo = () => {

		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return false;
		}

		//console.log(tokenInfo);

		setDisableTfaBtn(true);

		delete_tfa_to_cfa( tokenInfo.getAuthToken, deleteTfaFormData.tfaId )
			.then( (res) => {
				//console.log('Deleting TFA: ', res.data);
				refreshTfas(userId);
				setProfileForm('tfa');
				UpdateTfaFormData( STOCK_TFA );
				setRequireHomeCounty( (STOCK_TFA.homeState.toLowerCase() === 'nj') )
				setDisableTfaBtn(false);
			} )
			.catch( error => {
				console.error('[Delete TFA] Failed.', error);
				if( error.statusCode && error.statusCode === 500) {
					console.error('Could not add TFA for this CFA.');
				}
				setDisableTfaBtn(false);
			});
	}

	
	const generateTfaTable = () => {
		return (
			<div className="columns small-12" style={{display: (profileForm==='tfa' ? 'block' : 'none')}}>
				<div>
					<h3>CFA's TFAs</h3>
					<div className={"callout " + tfaAlert.type} style={{display:tfaAlert.display}} data-closable>
						<h5>{tfaAlert.title}</h5>
						<p>{tfaAlert.message}</p>
						<button className="close-button" aria-label="Dismiss alert" type="button" onClick={(e)=>{
							window.jQuery( window.jQuery(e.currentTarget).closest('div') ).fadeOut('slow', () => {
								setTfaAlert({
									type: 'success',
									display: 'none',
									title: '',
									message: ''
								})
							});
						}}
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				</div>
				<div>
					<button className="button small primary" onClick={(e) =>{ UpdateTfaFormData(STOCK_TFA); setProfileForm( 'tfa-form' ) }}>Add A New TFA</button>
				</div>
				<div className="table-scroll">
					<table className="table-left-align-all">
						<thead>
							<tr>
								<th>
									<button className="sortable-btn"
										onClick={()=>{
											updateTfaSortable('lastName', (
												sortedTfaTable.var==='lastName' && sortedTfaTable.dir==='asc' 
												? 'desc' : 'asc'
											));
										}}
									>
										<span>Last Name</span> {(
											sortedTfaTable.var==='lastName' ?
											<i className={`fas fa-${(
												sortedTfaTable.dir === 'asc' ? 'sort-alpha-down' : 'sort-alpha-down-alt'
											)}`}></i>
											: <i className="fas fa-sort"></i>
										)}
									</button>
								</th>
								<th>First Name</th>
								<th>
									<button className="sortable-btn"
										onClick={()=>{
											updateTfaSortable('email', (
												sortedTfaTable.var==='email' && sortedTfaTable.dir==='asc' 
												? 'desc' : 'asc'
											));
										}}
									>
										<span>Email</span> {(
											sortedTfaTable.var==='email' ?
											<i className={`fas fa-${(
												sortedTfaTable.dir === 'asc' ? 'sort-alpha-down' : 'sort-alpha-down-alt'
											)}`}></i>
											: <i className="fas fa-sort"></i>
										)}
									</button>
								</th>
								<th>
									<button className="sortable-btn"
										onClick={()=>{
											updateTfaSortable('payId', (
												sortedTfaTable.var==='payId' && sortedTfaTable.dir==='asc' 
												? 'desc' : 'asc'
											));
										}}
									>
										<span>Paid / Approved</span> {(
											sortedTfaTable.var==='payId' ?
											<i className={`fas fa-${(
												sortedTfaTable.dir === 'asc' ? 'sort-alpha-down' : 'sort-alpha-down-alt'
											)}`}></i>
											: <i className="fas fa-sort"></i>
										)}
									</button>
								</th>
								<th>Active Year</th>
								<th>Payment Id</th>
								<th>Edit</th>
								<th>Delete</th>
							</tr>
						</thead>
						<tbody>
							{getCurrentTfas()}
						</tbody>
					</table>
				</div>
			</div>
		);
	}

	
	
	//CE Course
	
	const [ceCourseAlert, setCECourseAlert] = useState({
		type: 'success',
		display: 'none',
		title: '',
		message: ''
	});
	const [showCoursesByType, setShowCoursesByType] = useState('completed');
	const [courseList, setCourseList] = useState({
		upcoming: [],
		registered: []
	});
	const [selectedCourseId, setSelectedCourseId] = useState(null);
	const [courseRegistration, setCourseRegistration] = useState(null);
	
	const getCourseList = (userId) => {
		setCourseRegistration(null);

		const tokenInfo = DECODE_TOKEN();
		if (tokenInfo.code !== 'TOKEN_FOUND') {
			console.error('Did not find appropriate token.');
			return () => { };
		}

		get_ce_courses_and_cfa_credits(tokenInfo.getAuthToken, userId)
			.then((res) => {
				//console.log('Retrieving CE courses and CFA CE Credit items.', res);
				//const [ courseList, cfaCredits ] = [
				//res[0].data.filter, res[1].data
				//];
				if (res[0].status !== 200 || res[1].status !== 200) {
					throw res;
				}
				if (res[0].data.code !== 'ACTIVE_COURSES' || res[1].data.code !== 'OK') {
					throw res;
				}

				//Remove CFA Exam
				const cfaExam = res[0].data.data.find((courseInfo) => {
					return courseInfo.title.toLowerCase() === 'cfa exam';
				});

				const [courseList, cfaCredits] = [
					//res[0].data.data.find( (courseInfo) => { return courseInfo.title.toLowerCase() === 'cfa exam'; } ),
					res[0].data.data.filter((courseInfo) => {
						return courseInfo.title.toLowerCase() !== 'cfa exam';
					}),
					res[1].data.credits.filter((courseInfo) => {
						return courseInfo.courseId !== cfaExam.courseId;
					}),
				];
							console.log('Available vars?', {
								cfaExam,
								courseList,
								cfaCredits
							})

				courseList.forEach((myCourseInfo) => {
					const myCfaCreditItem = cfaCredits.find((courseInfo) => {
						return courseInfo.courseId === myCourseInfo.courseId;
					});
					myCourseInfo.creditObj = myCfaCreditItem;
				});

				// I guess we should reverse sync....
				/*
				cfaCredits.forEach((myCreditItem) => {
					const myCourseItem = courseList.find((courseInfo) => {
						return courseInfo.courseId === myCreditItem.courseId;
					});
					myCreditItem.courseAvailable = (myCourseItem ? myCourseItem.beginDate : null);
				});
				*/


				setCourseList({
					upcoming: courseList,
					registered: cfaCredits //res[1].data.credits
				});

			})
			.catch(error => {

				//console.log('Retrieving CE courses and CFA CE Credit items.', error);

				setCourseList(null);
			});
	}
	
	const applyCredits = (creditId, creditEarned, payId, courseId) => {

		const tokenInfo = DECODE_TOKEN();
		if (tokenInfo.code !== 'TOKEN_FOUND') {
			console.error('Did not find appropriate token.');
			return () => { };
		}
//applyCredits = (creditId, creditEarned, payId, courseId)

		admin_apply_single_course_credit(
			tokenInfo.getAuthToken, userId, creditId, creditEarned, payId, courseId)
			.then((res) => {
				//console.log('Registering only for a course: ', res.data);
				if (res.data.code === 'OK') {
					//They're simply registering, not paying.

					setCourseRegistration(null);
					getCourseList(userId);
					setProfileForm('ce-courses');
				} else {
					throw new Error(res.data);
				}
			})
			.catch(error => {
				console.error('Getting [register_for_ce_course] Failed.', error);
				if (error.statusCode && error.statusCode === 500) {
					console.error('Could not register for this course..');
				}
			});

	}
	const signUpForCourse = (courseId) => {

		const tokenInfo = DECODE_TOKEN();
		if (tokenInfo.code !== 'TOKEN_FOUND') {
			console.error('Did not find appropriate token.');
			return () => { };
		}


		register_for_ce_course(tokenInfo.getAuthToken, userId, courseId)
			.then((res) => {
				//console.log('Registering only for a course: ', res.data);
				if (res.data.credit && res.data.code === 'OK') {
					//They're simply registering, not paying.

					setCourseRegistration(null);
					getCourseList(userId);
					setProfileForm('ce-courses');
				} else {
					throw new Error(res.data);
				}
			})
			.catch(error => {
				console.error('Getting [register_for_ce_course] Failed.', error);
				if (error.statusCode && error.statusCode === 500) {
					console.error('Could not register for this course..');
				}
			});

	}
	const generateCeTable = () => {

		if (showCoursesByType === 'upcoming') {

			if (!courseList.upcoming) {
				return (
					<tr><td colSpan="10"> There was a problem loading the upcoming <strong> Continuing Education </strong> courses.</td></tr>
				);
			}

			if (courseList.upcoming.length < 1) {
				return (
					<tr><td colSpan="10"> No upcoming <strong> Continuing Education </strong> courses are available at this time.</td></tr>
				);
			}
			/*
			
							<td> {
								(courseInfo.creditObj && courseInfo.creditObj.creditEarned ? 'Yes' : 'No')
							} </td>
			*/
			return (
				courseList.upcoming
					.filter((courseInfo) => {
						if( courseInfo.creditObj ){
							return false;
						}

						return true;
					})
					.map((courseInfo, key) => {
						return (<tr key={key}>
							<td> {courseInfo.courseId} </td> 
							<td> {
								courseInfo.title
							} </td>
							<td> {
								courseInfo.creditObj && courseInfo.creditObj.creditEarned 
								? courseInfo.creditObj.creditEarned
								: courseInfo.credits
							} </td>
							<td> {
								courseInfo.credits
							} </td><td> {
								Number(courseInfo.cost).toLocaleString('en-US', {
									style: 'currency',
									currency: 'USD'
								})
							} </td><td> {
								moment.tz(courseInfo.beginDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ', 'Etc/UTC')
									.tz(moment.tz.guess())
									.format('L')
							} </td><td> {
								(courseInfo.creditObj && courseInfo.creditObj.payId ? 'Yes' : 'No')
							} </td>
							<td> {
								!courseInfo.creditObj ?
									<button
										className="button small no-bottom-margin"
										onClick={
											(e) => {
												//console.log('Clicking btn');
												setCourseRegistration(courseInfo)
												setProfileForm('register-for-course-form');
											}
										} >
										<i className="fa fa-door-open" > </i> Register</button>
									: (
										!courseInfo.creditObj.creditEarned ?
										<button
											className="button small no-bottom-margin success override-sebs"
											onClick={
												(e) => {
													//console.log('Clicking btn');
													setCourseRegistration(courseInfo)
													setProfileForm('complete-credit-form');
												}
											} >
											<i className="fa fa-certificate" > </i> Apply Credit</button>
											: null
									)
									
							}
							</td></tr>
						)
					})
			);


		}
		console.log('[registered courses] ', courseList.registered);
		return (
			courseList.registered
				.filter((courseInfo) => {
					let showInTable = true;
					////console.log(courseInfo);
					switch (showCoursesByType) {

						case 'completed': {
							showInTable = showInTable && (courseInfo.creditEarned !== null);
							break;
						}
						case 'registered': {
							showInTable = showInTable && (courseInfo.creditEarned === null);
							break;
						}
						case 'all':
						default: {
							break;
						}
					}



					return showInTable;
				})
				.map((courseInfo, key) => {

					//console.log(courseInfo.payId, (courseInfo.payId ? 'Yes' : 'No'));
					/*
					
							<td> {
								courseInfo.course.credits
							} </td>
					*/
					return (
						<tr key={key}>
							<td> {
								courseInfo.courseId
							} </td><td> {
								courseInfo.course.title
							} </td>
							<td> {
								courseInfo.creditEarned 
								? courseInfo.creditEarned
								: courseInfo.course.credits
							} </td>
							
							<td> {
								Number(courseInfo.course.cost).toLocaleString('en-US', {
									style: 'currency',
									currency: 'USD'
								})
							} </td>
							<td> { (courseInfo.courseAvailable ?
									moment.tz(courseInfo.courseAvailable, 'YYYY-MM-DDTHH:mm:ss.SSSZ', 'Etc/UTC')
										.tz(moment.tz.guess())
										.format('L') :
									'--'
								) }</td>
							<td> {(courseInfo.payId ? 'Yes' : 'No') } </td>
							<td>  </td>
						
						</tr>
					)
				})
		);


	}
	const generateCeCourses = () => {
		return (<>
			<div className="columns small-12" style={{display: (profileForm==='ce-courses' ? 'block' : 'none')}}>
				<div>
					<div >
						<h3>CFA's Courses</h3>
						<div className={"callout " + ceCourseAlert.type} style={{display:ceCourseAlert.display}} data-closable>
							<h5>{ceCourseAlert.title}</h5>
							<p>{ceCourseAlert.message}</p>
							<button className="close-button" aria-label="Dismiss alert" type="button" onClick={(e)=>{
								window.jQuery( window.jQuery(e.currentTarget).closest('div') ).fadeOut('slow', () => {
									setCECourseAlert({
										type: 'success',
										display: 'none',
										title: '',
										message: ''
									})
								});
							}}
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						
						<div className="button-group" >
							  <button className={
								"button small primary" +
								(showCoursesByType === 'registered' ? ' is-selected' : '')
							}
								onClick={
									(e) => {
										setShowCoursesByType('registered')
									}
								} >Registered </button> <button className={
									"button small primary" +
									(showCoursesByType === 'upcoming' ? ' is-selected' : '')
								}
									onClick={
										(e) => {
											setShowCoursesByType('upcoming')
										}
									} >
									New Courses </button> <button className={
									"button small primary" +
									(showCoursesByType === 'completed' ? ' is-selected' : '')
								}
									onClick={
										(e) => {
											setShowCoursesByType('completed')
										}
									} >
								Completed </button>

						</div> 
						
						<div className="table-scroll" >
							<table className="table-left-align-all">
								<thead>
									<tr>
										<th> Course Id </th>
										<th> Title </th>
										<th>Credits</th>
										<th> Cost </th>
										<th> Date </th>
										<th> Paid For </th>
										<th></th>
									</tr>
								</thead> 
								<tbody>
									{generateCeTable()}
								</tbody>
							</table>
						</div>

					</div>
				</div>
			</div>
		</>)
	}

	const CourseInformation = () => {
		if( !courseRegistration ) {
			return null;
		}


		if( profileForm === 'complete-credit-form' ) {
			//console.log(courseRegistration);
			return (<>
				<div className="cell small-12" style={{display: ( profileForm === 'complete-credit-form' ? 'block' : 'none' )}}>
					<h2> Course Registration Form </h2>
					<div>
						<h4> This user will recieve [{courseRegistration.credits} credit(s)] for the following course: </h4> 
						<ul>
							<li> Course: {courseRegistration.title} </li> 
							<li> Description: {courseRegistration.description || 'N/A'} </li> 
							<li> Cost: {
								Number(courseRegistration.cost).toLocaleString('en-US', {
									style: 'currency',
									currency: 'USD'
								})
							} </li> 
						</ul> 
						<p>
							Credit will apply even if they have not paid for the course. This action cannot be undone.
						</p>
						<div>
	
							<button className="button small alert"
								onClick={ (e) => {
									setCourseRegistration(null)
									setProfileForm('ce-courses');
								} } >
								<i className="fa fa-backspace"></i> Cancel </button> 
								<button className="button small primary"
									onClick={(e) => {
										//applyCredits = (creditId, creditEarned, payId, courseId)
										applyCredits(
											courseRegistration.creditObj.creditId,
											courseRegistration.credits,
											courseRegistration.creditObj.payId,
											courseRegistration.creditObj.courseId
										)
									} }
								> Apply Credits
							</button> 
						</div> 
					</div> 
				</div>
			</>);
		}

		return (<>
			<div className="small-12  columns" style={{display: ( profileForm === 'register-for-course-form' ? 'block' : 'none' )}}>
				<h2> Course Registration Form </h2>
				<div>
					<h4> What you will be registering this user for: </h4> 
					<ul>
						<li> Course: {courseRegistration.title} </li> 
						<li> Description: {courseRegistration.description || 'N/A'} </li> 
						<li> Cost: {
							Number(courseRegistration.cost).toLocaleString('en-US', {
								style: 'currency',
								currency: 'USD'
							})
						} </li> 
					</ul> 
					<p>
						After you register this user for this course, you will be able to access it for payment on the other tab. 
					</p>
					<div>

						<button className="button small alert"
							onClick={ (e) => {
								setCourseRegistration(null)
								setProfileForm('ce-courses');
							} } >
							<i className="fa fa-backspace"></i> Cancel </button> 
							<button className="button small primary"
								onClick={(e) => {
									signUpForCourse(courseRegistration.courseId)
								} }
							> Register for this course 
						</button> 
					</div> 
				</div> 
			</div>
		</>);
	}
	
	useEffect( ()=> {
		refreshTfas(userId);
		refreshPayHistory(userId);
		getCourseList(userId);
	}, [userId] );

	return (
		<>
			<div className="small-12 columns">
				<div>
					<hr className="slim-hr" />
					<h3> CFA Profile and Settings </h3>
					<h4> {personalInfo.firstName} {personalInfo.lastName}'s Profile </h4>
					<div className="stacked-for-small button-group">
						<button className={"button primary" + ( (profileForm === 'personal' ? ' is-selected' : '') ) } onClick={(e)=>setProfileForm('personal')}> <i className="fas fa-user"></i> Personal </button>
						
						<button className={"button primary" + ( (profileForm === 'home' ? ' is-selected' : '') ) }  onClick={(e)=>setProfileForm('home')}> <i className="fas fa-home"></i> Home </button>
						<button className={"button primary" + ( (profileForm === 'business' ? ' is-selected' : '') ) }  onClick={(e)=>setProfileForm('business')}> <i className="fas fa-building"></i> Business </button>
						
						<button className={"button primary" + ( (profileForm === 'update-payments' ? ' is-selected' : '') ) }  onClick={(e)=>{
							refreshPayHistory(userId);
							setProfileForm('update-payments')
						}}> <i className="fas fa-cash-register"></i> 
							Update Payments </button>
						<button className={"button primary" + ( (profileForm === 'tfa' ? ' is-selected' : '') ) }  onClick={(e)=>setProfileForm('tfa')}> <i className="fas fa-users"></i> TFAs </button>
						<button className={"button primary" + ( (profileForm === 'ce-courses' ? ' is-selected' : '') ) }  onClick={(e)=>setProfileForm('ce-courses')}> <i className="fas fa-book"></i> CE Courses </button>
						<button className={"button primary" + ( (profileForm === 'merge-acct' ? ' is-selected' : '') ) }  
							onClick={(e)=>setProfileForm('merge-acct')}> <i className="fas fa-people-arrows"></i> Merge Old Accounts </button>
					</div>
				</div>
			</div>
					
					{generateProfileForm()}

					{generateHomeForm()}
					{generateBusinessForm()}

					{generatePayHistory()}
						{PayForm()}
						{DeletePayForm()}

					{generateTfaTable()}
						{TfaForm()}	
						{DeleteTfaForm()}

					{generateCeCourses()}
						{CourseInformation()}

					{generateMergeAccountForm()}
					
			<div className="small-12 columns">
				<hr />
				<div>
					<button className="no-bottom-margin button primary small"
						onClick={ (e) => { confirmEditing() }}
					><i className="fas fa-angle-left"></i> Back To List</button>
				</div>
			</div>
		</>
	);
};

export default EditCFARecord;