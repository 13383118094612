import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
//import parse from 'html-react-parser';
import { 
	admin_dashboard_stats, 
	admin_quick_peek,
	DECODE_TOKEN 

} from '../../../Api/api';
import USER_GUIDE from '../../../user-guides/Profact_User_Walkthrough.pdf';
import ADMIN_GUIDE from '../../../user-guides/Profact-Admin-Walkthrough.pdf';
/*
import axios from 'axios';
import Config from '../../../Config';
*/


const AdminDashboard = (props) => {

	const [firstName, lastName] = [props.UserState.user.data.firstName, props.UserState.user.data.lastName];
	/*
	const statistics = [
		{label: 'Number of CFAs', value: 1}
	];
	*/
	const [statistics, setStatsContent] = useState({
		isLoading: true,
		hasError: false,
		stats: []
	});

	//var names pending what's on swagger.
	const [searchCriteria, SetSearchCriteria] = useState({
		cfaLastName: '',
		cfaFirstName: ''
	})
	const [cfaInfo, SetCFAInfo] = useState({
		isLoading: false,
		hasError: false,
		isClear: true,
		list: []
	});


	const clearSelection = () => {
		SetCFAInfo({
			isLoading: false,
			hasError: false,
			isClear: true,
			list: []
		});
	}
	const searchCriteria_HandleChange = (e_target) => {
		const { name, value } = e_target;
		SetSearchCriteria({
			...searchCriteria,
            [name]: value
		});
	}
	const Find_User = () => {
		
		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return () => {};
		}

		admin_quick_peek(tokenInfo.getAuthToken, searchCriteria.cfaLastName, searchCriteria.cfaFirstName)
		.then( (res) => {
			console.log('Quick Peek!', res.data);
			if( res.data.data && res.data.code === 'OK') {
				//console.log(res.data);
				//setStatsContent(res.data.statistics);
				SetCFAInfo({
					isLoading: false,
					hasError: false,
					isClear: false,
					list: res.data.data
				});
			}else{
				throw new Error(res.data);
			}
		} )
		.catch( error => {
			console.error('Getting [Admin Statistics] Failed.', error);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Could not fetch statistics.');
			}
			SetCFAInfo({
				isLoading: false,
				hasError: true,
				isClear: false,
				list: []
			});
		});
	}
	const searchForUser = () => {
		console.log('Searching with the criteria: ', searchCriteria);
		SetCFAInfo({
			isLoading: false,
			hasError: true,
			isClear: true,
			list: []
		});
		Find_User();
		return true;
	}


/*
	const recentActivity = [
		{datetime: '7/23/2020 01:45 PM', log: 'Items in list constant.'},
		{datetime: '7/22/2020 01:45 PM', log: 'User (Testy McTesterson) Failed Exam'},
		{datetime: '7/22/2020 01:30 PM', log: 'CFA (Daniel Farnsworth) Added TFAs'},
		{datetime: '7/22/2020 01:15 PM', log: 'CFA (Daniel Farnsworth) Paid (Payment ID #1)'},
		{datetime: '7/22/2020 12:30 PM', log: 'CFA (Daniel Farnsworth) Passed Exam'},
		{datetime: '7/22/2020 12:23 PM', log: 'New User (Daniel Farnsworth) Registered'},
		{datetime: '7/21/2020 12:23 PM', log: 'User (Testy McTesterson) Failed Exam'},
	];
*/
	const Get_Statistics = () => {

		const tokenInfo = DECODE_TOKEN();
		if( tokenInfo.code !== 'TOKEN_FOUND' ) {
			console.error('Did not find appropriate token.');
			return () => {};
		}

		admin_dashboard_stats(tokenInfo.getAuthToken)
		.then( (res) => {
			
			if( res.data.statistics && res.data.code === 'OK') {
				console.log(res.data);
				setStatsContent({
					isLoading:false,
					hasError: false,
					stats: res.data.statistics
				});
			}else{
				throw new Error(res.data);
			}
		} )
		.catch( error => {
			console.error('Getting [Admin Statistics] Failed.', error);
			if( error.statusCode && error.statusCode === 500) {
				console.error('Could not fetch statistics.');
			}
			setStatsContent({
				isLoading:false,
				hasError: true,
				stats: []
			});
		});

	}

	const Get_Stat_List = () => {
		//console.log(statistics);

		if( statistics.isLoading ) {
			return (<li>Loading Statistics...</li>)
		}
		if( statistics.hasError ) {
			return (<li>Problem Loading Statistics</li>)
		}

		const thisYear = new Date().getFullYear();
		const lastYear = thisYear-1;




		const myStatMap = [];

		myStatMap.push({
			title: 'Total Certified',
			obj: statistics.stats.totalCertified
		});
		myStatMap.push({
			title: 'Paid and Up-To-Date',
			obj: statistics.stats.paidRenewal
		});
		myStatMap.push({
			title: 'Revoked',
			obj: statistics.stats.revoked
		});
		//Certified this year and last year
		const findCertThisYear = statistics.stats.certByYear.find( (certByYear) => {
			return Number(certByYear.certYear) === thisYear;
		}) || {year:thisYear,count:'N/A'};
		const findCertLastYear = statistics.stats.certByYear.find( (certByYear) => {
			return Number(certByYear.certYear) === lastYear;
		}) || {year:lastYear,count:'N/A'};

		myStatMap.push({
			title: 'New Certifications',
			obj: <ul>
				<li>This Year: {findCertThisYear.count} </li>
				<li>Last Year: {findCertLastYear.count} </li>
			</ul>
		});
		
		//Revoke retake by year
		const findRevokeThisYear = statistics.stats.revokeRetakeByYear.find( (revokeByYear) => {
			return Number(revokeByYear.certYear) === thisYear;
		}) || {year:thisYear,count:'N/A'};
		const findRevokeLastYear = statistics.stats.revokeRetakeByYear.find( (revokeByYear) => {
			return Number(revokeByYear.certYear) === lastYear;
		}) || {year:lastYear,count:'N/A'};

		myStatMap.push({
			title: 'How many re-take exam each year who were revoked​',
			obj: <ul>
				<li>This Year: {findRevokeThisYear.count} </li>
				<li>Last Year: {findRevokeLastYear.count} </li>
			</ul>
		});

		myStatMap.push({
			title: 'Paid for Exam but didn’t take and/or pass​',
			obj: <>{statistics.stats.paidButNotPass.length}
				<ul>
				{statistics.stats.paidButNotPass.map( (who) => {
					return <li>[#{who.id}] {who.firstName} {who.lastName}</li>
				})}
				</ul>
			</>
		});
		//Spanish exam stats
		const findSpanishExamPassed = statistics.stats.spanishPassFail.find( (spStats) => {
			return spStats.passed===1;
		}) || {passed:1,count:'N/A'};
		const findSpanishExamFailed = statistics.stats.spanishPassFail.find( (spStats) => {
			return spStats.passed===0;
		}) || {passed:0,count:'N/A'};

		myStatMap.push({
			title: 'Spanish Exams Statistics',
			obj: <ul>
				<li>Passed: {findSpanishExamPassed.count} </li>
				<li>Failed: {findSpanishExamFailed.count} </li>
			</ul>
		});
		
		//TFAs
		const findTfasThisYear = statistics.stats.tfaCount.find( (tfaStats) => {
			return Number(tfaStats.year) === thisYear;
		}) || {passed:1,count:'N/A'};
		const findTfasLastYear = statistics.stats.tfaCount.find( (tfaStats) => {
			return Number(tfaStats.year) === lastYear;
		}) || {passed:0,count:'N/A'};

		myStatMap.push({
			title: 'TFAs Paid',
			obj: <ul>
				<li>This Year: {findTfasThisYear.count} </li>
				<li>Last Year: {findTfasLastYear.count} </li>
			</ul>
		});
		
		myStatMap.push({
			title: 'Outstanding purchase orders that have not been paid ​',
			obj: statistics.stats.unpaid.length
		});
		
		return myStatMap.map( (stat, key)  => { 
			return (<li key={key}><strong>{stat.title}</strong>: {stat.obj}</li>) 
		} );
		/*
		

		myStatMap.push({
			statusType: `# active CFAs`, 
			stat: `<ul><li>${statistics.certByYear[0].count} (${statistics.certByYear[0].certYear})</li><li>${statistics.certByYear[1].count} (${statistics.certByYear[1].certYear})</li></ul>` 
		});
		
		myStatMap.push({
			statusType: `# active TFAs`, 
			stat: `<ul><li>${statistics.tfaCount[0].count} (${statistics.tfaCount[0].year})</li><li>${statistics.tfaCount[1].count} (${statistics.tfaCount[1].year})</li></ul>` 
		});
			
		myStatMap.push({
			statusType: `Expiring this year`, 
			stat: `N/A` 
		});
		myStatMap.push({
			statusType: `Retakes`, 
			stat: `N/A` 
		});
		myStatMap.push({
			statusType: `Passed`, 
			stat: `N/A` 
		});
		myStatMap.push({
			statusType: `Failed`, 
			stat: `N/A` 
		});
		myStatMap.push({
			statusType: `Paid - But did not take`, 
			stat: `N/A` 
		});
		*/

//{statistics.map( (stat, key)  => { return (<li key={key}><strong>{stat.statusType}</strong>: {stat.stat}</li>) } )}
/*
# active CFAs this year vs. last year​
# active TFAs this year vs. last year​
# expiring this year (5 year cycle)​
# retakes
# passed​
# failed​
# paid but didn't take
*/
	}


	useEffect( () => {
		Get_Statistics();
	}, [] );



	const _GetCfaPeek = () => {
		if( cfaInfo.isClear ) {
			return(<p>Fill out the form above and press the &ldquo;Look Up CFA&rdquo; Button</p>)
		}

		if( cfaInfo.isLoading ) {
			return(<p><i className="fas fa-circle-notch fa-spin"></i> Searching...</p>)
		}
		
		if( cfaInfo.hasError ) {
			return(<p> There was a problem looking for CFAs</p>)
		}

		return (
			<table>
				<thead>
					<tr>
						<th>CFA</th>
						<th>First Name</th>
						<th>Last Name</th>
						<th>Email</th>
						<th>Credits</th>
						<th>Unpaid Payments</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{
						cfaInfo.list.map( (cfaApp) => {
							const countPayments = cfaApp.payments.filter( (payInfo) => {
								return payInfo.paid === false;
							}).length;
							return(
								<tr>
									<td>{cfaApp.examPassed ? <i className="fas fa-certificate"></i> : null }</td>
									<td> {cfaApp.firstName} </td>
									<td> {cfaApp.lastName} </td>
									<td> {cfaApp.email} </td>
									<td> {cfaApp.credits} </td>
									<td> {countPayments} </td>
									<td> <Link to={`/manage-users/${cfaApp.id}`} className="button primary small no-bottom-margin"> 
										<i className="fas fa-eye"></i> View </Link>
									</td>
								</tr>
							);
						})
					}
				</tbody>
			</table>
		);
	}
/*
	<Link to="/manage-users" className="button primary"> <i className="fas fa-users"></i> Manage All User Information </Link>
	<Link to="/manage-user-payments" className="button primary"> <i className="fas fa-credit-card"></i> Manage User Payments </Link>
	<Link to="/manage-ce-credits" className="button primary"> <i className="fas fa-feather-alt"></i> Apply C.E. Credits </Link>
	<Link to="/profile" className="button primary"> <i className="fas fa-id-card"></i> Update <strong>Your</strong> Profile / Password </Link>
*/


/*

					<a href={USER_GUIDE} className="button primary" target="_blank" rel="noopener noreferrer">ProFACT User Guide</a>
*/
	return(
		<>
		<div className="">

			<div className=" small-12 medium-12 large-7 columns">
				<h2> Admin Dashboard </h2>
				<h3> Welcome {firstName} {lastName} </h3>
				<h4> Here are some tasks you can check up on.</h4>
				<h5>User Guides</h5>
				<div className="button-group stacked-for-small button-group-override">
					<a href={ADMIN_GUIDE} className="button primary" target="_blank" rel="noopener noreferrer">ProFACT Administrator Guide</a>
					<a href={USER_GUIDE} className="button primary" target="_blank" rel="noopener noreferrer">ProFACT User Guide</a>
				</div>
				<h5>User Information / Modification</h5>
				<div className="button-group stacked-for-small button-group-override">
					<Link to="/manage-users" className="button primary"> <i className="fas fa-users"></i> Manage Users </Link>
					<Link to="/manage-user-payments" className="button primary"> <i className="fas fa-credit-card"></i> Manage Payments </Link>
					<Link to="/manage-ce-credits" className="button primary"> <i className="fas fa-feather-alt"></i> Apply C.E. Credits </Link>
					<Link to="/profile" className="button primary"> <i className="fas fa-id-card"></i> <strong>Your</strong> Profile </Link>
				</div>
				<h5>Online Course / Exam Tasks</h5>
				<div className="button-group stacked-for-small button-group-override">
					<Link to="/manage-courses/cfa" className="button primary"> <i className="fas fa-book"></i> Manage CFA Training Content </Link>
					<Link to="/manage-courses/" className="button primary"> <i className="fas fa-book-medical"></i> Manage Continuing Education Content </Link>
				</div>
				<h5>Website Tasks and Abilities</h5>
				<div className="button-group stacked-for-small button-group-override">
					<Link to="/manage-news" className="button primary"> <i className="fas fa-newspaper"></i> Update News Content </Link>
					<Link to="/mass-mailer" className="button primary"> <i className="fas fa-mail-bulk"></i> Mail Merge / Mass Mailer </Link>
					<Link to="/download-reports" className="button primary"> <i className="fas fa-file-csv"></i> Download Reports </Link>
				</div>
			</div>
			<div className=" small-12 medium-12 large-5 columns">
				<div className="panel">
					<h2> Statistics for the Website </h2>
					<ul className="no-extra-margins">
						{Get_Stat_List()}
					</ul>
				</div>
			</div>
			<div className=" small-12 medium-12 columns">
				<hr />
			</div>
			<div className=" small-12 medium-12 columns">
				<h4> Quick CFA Peek </h4>
			</div>
			<div className="small-12 medium-9 columns">
				
				<div className="row">
					<div className="medium-3 small-12 columns">
						<label htmlFor="cfaLastName" className="label-style-1">CFA Last name: </label>
					</div>
					<div className="medium-9 small-12 columns">
						<input type="text" id="cfaLastName" placeholder="CFA's Last Name"  
							name="cfaLastName"
							onChange={(e) => {searchCriteria_HandleChange(e.target);} }
						/>
					</div>
				</div>
				<div className="row">
					<div className="medium-3 small-12 columns">
						<label htmlFor="cfaFirstName" className="label-style-1">CFA First name: </label>
					</div>
					<div className="medium-9 small-12 columns">
						<input type="text" 
							id="cfaFirstName" 
							name="cfaFirstName"
							placeholder="CFA's Frist Name" 
							onChange={(e) => {searchCriteria_HandleChange(e.target);} }
						/>
					</div>
				</div>
			</div>
			<div className="small-12 medium-3 align-self-end columns">
				<button type="button" className="button primary"
					onClick={(e) => {searchForUser()}}
				>Look Up CFA</button> <button type="button" className="button warning"
				onClick={(e) => {clearSelection()}}>Clear Results</button>
			</div>
			<div className="columns small-12">
				{_GetCfaPeek()}
			</div>
		</div>
		</>
	);
	/*
			<div className="cell small-12 medium-12">
				<hr />
			</div>

			<div className="cell small-12 medium-12">
				<h4> Recent User Activity </h4>
				<ul>
					{recentActivity.map( (activity, key)  => { return (<li key={key}><strong>{activity.datetime}</strong>: {activity.log}</li>) } )}
				</ul>
			</div>
	*/
};

export default AdminDashboard;